import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postDisputePaypalPullReportRequest } from 'redux/actions';
import { SingleAccountBreadcrumb, Pagination } from 'components';
import styles from './style.module.scss';
import { BreadcumbItem } from 'components/ui';
import { getItem } from 'utils/localStorage';
import { useBreadCumbs } from 'hooks';
import Loading from 'components/loading/loading';
import PaypalReportList from 'components/disputeDashboard/disiputePaypalReports';
import { Button } from '@mui/material';

type TShop = {
  value: number;
  label: string;
};
const paypalReportsHeading = [
  'Dispute ID',
  'Store Name',
  'Date',
  'Amount',
  'Reason',
  'Response Due',
  'Decision Date',
  'Status',
  'Carrier',
  'Last Mile Carrier',
  'Response Status',
];
interface Props {
  shop?: TShop;
}
const PaypalPullReports = ({ shop }: Props) => {
  const dispatch = useDispatch();
  const SingleAccountCheck = getItem('singleAccountCheck');
  const { breadCumbs } = useBreadCumbs({ pageName: 'Paypal Reports' });
  const [page, setPage] = useState(1);
  const tableList = 15;

  // this is the actual data
  const {isLoading,isSuccess,isError,data} = useSelector(
    (state: any) => state?.paypalPullReports?.papalReportsList
  );

  console.log(data, 'this is the actual paypal reports data');

  useEffect(() => {
    const payload = {
      page:1,
      pageLength:15,
    };
    dispatch(postDisputePaypalPullReportRequest(payload));
  },[page] );

  const onPageChange = (page: number) => {
    setPage(page);
  };

  return (
    <div className={styles.disputesWrapper}>
      {SingleAccountCheck === 'true' ? (
        <SingleAccountBreadcrumb />
      ) : (
        <div className={styles.nav}>
          <h3>Paypal Pull Reports</h3>
          <div className={styles.navPath}>
            {breadCumbs?.map((item, key) => (
              <BreadcumbItem key={key} {...item} />
            ))}
          </div>
        </div>
      )}
      <div>
        {isLoading ? (
          <div style={{ background: '#fff', borderRadius: '8px' }}>
            <Loading />
          </div>
        ) : (
          <>
            <PaypalReportList
              isLoading={isLoading}
              isSuccess={isSuccess}
              isError={isError}
              data={data}
              tableHeadings={paypalReportsHeading}
              onPageChange={onPageChange}
              tableList={tableList}
              page={page}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default PaypalPullReports;
