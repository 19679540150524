import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { BlueTick, svgIcons } from 'elements';
import { getDisputeCurrency } from 'utils/disputeUtils';
import styles from './style.module.scss';
import dayjs from 'dayjs';
import { HtmlTooltip } from 'components/ui';
import ToolTipTitle from 'components/tooltip/ToolTipTitle';
import Loading from 'components/loading/loading';
import Image from 'components/image';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { usePdfDownloadResponse, useResponseSaveContent } from 'hooks';


export type DisputeResponseData = {
  id:string;
  createTime:string;
  updateTime:string;
  status:string;
  paymentProcessor:string;
  reason:string;
  networkReasonCode:any;
  disputeState:string;
  disputeLifeCycleStage:string;
  order:any;
  storeOrderId:string;
  orderName:any;
  shopName:string;
  shopType:any;
  sellerMerchantId:string;
  disputeAmountValue:number;
  disputeAmountCurrencyCode:string;
  sellerResponseDueDate:any;
  buyerResponseDueDate:any;
  decisionDate:any;
  sellerTransactionId:string;
  paypalId:any;
  email:any;
  payerEmail:any;
  disputeFlowId:any;
  disputeOutcome:any;
  trackiScore:number;
  resolutionEstimatedTime:any;
  disputeFlowMapping:any;
  shopId:number;
  actions:any;
  orderLink:any;
  submittedByChargePay:boolean;
  manager:string;
  chargeCreated:boolean;
  chargePay:boolean;
  completed:boolean;
  flowId:any;
  submissionMode:any;
  myShopifyDomain:any;
  chargeActive:boolean;
  flowName:string;
  templateName:string;
  creationDate:any;
  updateDate:any;
  approvalDate:any;
  submissionDate:any;
  responseCreatedBy:string;
  responseUpdatedBy:string;
  responseApprovedBy:any;
  responseStatus:string;
  responseId:number;
  disputeResponseSubmitted:any;
  ipAddress:any;
  carrier:string;
  lastMileCarrier:string;
  billableAmount:any;
  disputeJiraLink:any;
  transactionDate:string;
}
export type Props = {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  noSellerResponse?: boolean;
  data: {
    disputeResponseList: DisputeResponseData[];
    count: number;
  };
  DisputeListColumnNames: string[];
  handleDisputeStatus: (dispute: DisputeResponseData) => string;
  isShowCollapsibleTablel?: boolean;
}

const NewDisputeList: React.FC<Props> = ({
  isLoading,
  isSuccess,
  isError,
  data,
  DisputeListColumnNames,
  isShowCollapsibleTablel,
}) => {
  //TODO make this in a hook
  // sorted
  //  function for download pdf from custom hook
  const { handleDownloadPdfResponse, loadingResponseId } =
    usePdfDownloadResponse();
  const [expandedRow, setExpandedRow] = useState<string | null>(null);
  const {
    reStructureMacros,
    isLoading: isMacrosLoading,
    handleMissingMacros,
  } = useResponseSaveContent();
  const handleRowClick = (disputeId: string, responseId: number) => {
    setExpandedRow(expandedRow === disputeId ? null : disputeId);
    handleMissingMacros({
      disputeResponseId: responseId, // response id
      responseSection: 'HTML',
    });
  };

  return (
    <div className={styles.tableWrapper}>
      {isLoading && <Loading />}
      {(!isLoading && isError) || (isSuccess && !data?.count) ? (
        <div className={styles.NoData}>
          <img src={svgIcons.Coffee_Break} alt='disputes' />
          {isError && <h3>Oops! Something went wrong. Please try again.</h3>}
          <h3>No waiting seller response found</h3>
        </div>
      ) : (
        isSuccess &&
        data?.count > 0 && (
          <>
            <table>
              <thead>
                <tr>
                  {DisputeListColumnNames.length > 0 &&
                    DisputeListColumnNames.map((item: string) => (
                      <th key={item}>{item}</th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {data?.disputeResponseList.length > 0 &&
                  data?.disputeResponseList.map((dispute: DisputeResponseData) => (
                    <React.Fragment key={dispute.id}>
                      <tr className={styles.disputeWrapper}>
                        {isShowCollapsibleTablel && (
                          <td>
                            <Tooltip
                              placement='left'
                              arrow
                              title={
                                expandedRow === dispute.id ? 'Close' : 'Expand'
                              }
                            >
                              <IconButton
                                aria-label='expand row'
                                size='small'
                                onClick={() =>
                                  handleRowClick(dispute.id, dispute.responseId)
                                }
                              >
                                {expandedRow === dispute.id ? (
                                   <KeyboardArrowUp />
                                ) : (
                                  <KeyboardArrowDown />
                                )}
                              </IconButton>
                            </Tooltip>
                          </td>
                        )}
                        <td data-testid='dispute-order'>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            {dispute?.paymentProcessor && (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {dispute?.submittedByChargePay && (
                                  <div
                                    style={{
                                      marginRight: '5px',
                                      marginTop: '5px',
                                    }}
                                  >
                                    <BlueTick />
                                  </div>
                                )}
                                <Image
                                  icon={dispute?.paymentProcessor}
                                  altText='payment processor'
                                />
                              </div>
                            )}
                            <div>
                              <Link
                                className={styles.id}
                                to={`/dispute/${dispute?.id}`}
                              >
                                <p className={styles.recentDisputeID}>
                                  {dispute?.id ?? 'Missing Dispute'}
                                </p>
                              </Link>
                              <Link
                                className={styles.id}
                                to={
                                  dispute?.shopId
                                    ? `/store-details/${dispute?.shopId}`
                                    : '#'
                                }
                              >
                                <p
                                  className={styles.recentDisputeID}
                                  style={{ marginTop: '10px' }}
                                >
                                  {dispute?.shopName ?? 'Missing Dispute'}
                                </p>
                              </Link>
                            </div>
                          </div>
                        </td>
                        <td>
                          {dispute?.status === 'UNDER_REVIEW'
                            ? dayjs(dispute.createTime).format('MMMM D, YYYY')
                            : dispute?.status === 'WAITING_FOR_SELLER_RESPONSE'
                            ? dayjs(dispute?.sellerResponseDueDate).format(
                                'MMMM D, YYYY'
                              )
                            : ''}
                        </td>
                        <td>
                          {
                            getDisputeCurrency(
                              dispute?.disputeAmountCurrencyCode
                            )?.currencySign
                          }
                          {Number(dispute?.disputeAmountValue)
                            ?.toFixed(2)
                            ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                        </td>
                        <td>
                          {dispute?.billableAmount !== null &&
                            getDisputeCurrency(
                              dispute?.disputeAmountCurrencyCode
                            )?.currencySign}
                          {dispute?.billableAmount}
                        </td>
                        <td>
                          <div className={styles.reasonType}>
                            <p>{dispute.reason?.split('_').join(' ')}</p>
                          </div>
                        </td>
                        {(!dispute?.carrier ||
                          dispute.carrier.trim() === '' ||
                          dispute.carrier.trim().toLowerCase() === 'null') &&
                        (!dispute?.lastMileCarrier ||
                          dispute.lastMileCarrier.trim() === '' ||
                          dispute.lastMileCarrier.trim().toLowerCase() ===
                            'null') ? (
                          <td colSpan={2}>
                            <p
                              style={{
                                backgroundColor: 'rgb(248, 226, 224)',
                                width: '110px',
                                color: '#000',
                                textAlign: 'center',
                                padding: '5px',
                                borderRadius: '4px',
                              }}
                            >
                              MISSING DATA
                            </p>
                          </td>
                        ) : (
                          <>
                            <td className={styles.carrierInfo}>
                              {dispute?.carrier ? (
                                <div className={styles.carrier}>
                                  <p className={styles.carrierData}>
                                    {dispute.carrier}
                                  </p>
                                </div>
                              ) : (
                                <div className={styles.carrier} />
                              )}
                            </td>
                            <td className={styles.carrierInfo}>
                              {dispute?.lastMileCarrier ? (
                                <div className={styles.carrier}>
                                  <p className={styles.carrierData}>
                                    {dispute.lastMileCarrier}
                                  </p>
                                </div>
                              ) : (
                                <div className={styles.carrier} />
                              )}
                            </td>
                          </>
                        )}
                        <td>
                          {dispute.responseStatus && (
                            <HtmlTooltip
                              placement='top-start'
                              arrow
                              title={<ToolTipTitle toolTipDetails={dispute} />}
                            >
                              <div
                                className={styles.reasonType}
                                style={{
                                  background:
                                    dispute.responseStatus === 'SUBMITTED'
                                      ? 'rgb(188 248 212)'
                                      : dispute.responseStatus ===
                                        'MISSING_INFO'
                                      ? 'rgb(248 226 224)'
                                      : 'rgb(252 228 154)',
                                }}
                              >
                                <p>{dispute.responseStatus}</p>
                              </div>
                            </HtmlTooltip>
                          )}
                        </td>
                        {dispute?.status === 'UNDER_REVIEW' && (
                          <td>
                            {dayjs(dispute?.submissionDate).format(
                              'MMMM D, YYYY'
                            )}
                          </td>
                        )}
                        <td>
                          <Button
                            variant='contained'
                            size='small'
                            onClick={() =>
                              handleDownloadPdfResponse(
                                dispute?.responseId,
                                dispute?.id,
                                null
                              )
                            }
                          >
                            {loadingResponseId === dispute?.responseId
                              ? 'loading...'
                              : 'Pdf'}
                          </Button>
                        </td>
                      </tr>
                      {expandedRow === dispute.id && (
                        <tr>
                          <td colSpan={DisputeListColumnNames.length}>
                            <Box
                              sx={{
                                margin: 0.5,
                                border: 1,
                                borderColor: 'grey.500',
                                padding: 2,
                                borderRadius: 1,
                              }}
                            >
                              <Typography
                                variant='h5'
                                gutterBottom
                                component='div'
                              >
                                Missing macros
                              </Typography>
                              <div>
                                {isMacrosLoading ? (
                                  <p style={{ fontWeight: 'bold' }}>
                                    loading...
                                  </p>
                                ) : (
                                  <div
                                    style={{
                                      display: 'grid',
                                      gridTemplateColumns: 'repeat(3,1fr)',
                                      gap: '15px',
                                    }}
                                    className={styles.collapsTableContainer}
                                  >
                                    {reStructureMacros?.length > 0 &&
                                      reStructureMacros?.map(
                                        (item: any, index: number) => {
                                          return (
                                            <div key={index}>{item?.label}</div>
                                          );
                                        }
                                      )}
                                  </div>
                                )}
                              </div>
                            </Box>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </>
        )
      )}
    </div>
  );
};

export default NewDisputeList;
