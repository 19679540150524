import useManagerList from "hooks/manageUser/useManagerList";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { removeItem } from "utils/localStorage";

export type DisputesData = {
    id?:string;
    shopName?:string;
    createTime?:string;
    updateTime?:string;
    status?:string;
    paymentProcessor?:string;
    reason?:string;
    networkReasonCode?:any;
    disputeState?:string;
    disputeLifeCycleStage?:string;
    order?:any;
    storeOrderId?:string;
    orderName?:any;
    shopType?:any;
    sellerMerchantId?:string;
    disputeAmountValue?:number;
    disputeAmountCurrencyCode?:any;
    sellerResponseDueDate?:string;
    buyerResponseDueDate?:any;
    decisionDate?:any;
    sellerTransactionId?:string;
    paypalId?:string;
    email?:any;
    payerEmail?:any;
    disputeFlowId?:any;
    disputeOutcome?:any;
    trackiScore?:number;
    resolutionEstimatedTime?:any;
    disputeFlowMapping?:any;
    shopId?:number;
    actions?:null;
    orderLink?:null;
    submittedByChargePay?:boolean;
    manager?:string;
    chargeCreated?:boolean;
    chargePay?:boolean;
    completed?:boolean;
    flowId?:any;
    submissionMode?:any;
    myShopifyDomain?:any;
    chargeActive?:boolean;
    flowName?:string;
    templateName?:string;
    creationDate?:any;
    updateDate?:string;
    approvalDate?:any;
    submissionDate?:any;
    responseCreatedBy?:string;
    responseUpdatedBy?:string;
    responseApprovedBy?:any;
    responseStatus?:string;
    responseId?:number;
    disputeResponseSubmitted?:any;
    ipAddress?:string;
    carrier?:string;
    lastMileCarrier?:string;
    billableAmount?:number;
    disputeJiraLink?:string
}
type disputeList = {
    data: {
        count:number;
        disputeResponseList: DisputesData[]
    };
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
};
const DisputeListColumnNames: string[] = [
    "Dispute ID",
    "Store Name",
    "Date",
    "Amount",
    "Reason",
    "Response Due",
    "Decision Date",
    "Status",
    "Carrier",
    "Last Mile Carrier",
    "Response Status",
    // "Manager"
]
const useDisputeList = () => {
    const { isLoading, isSuccess, isError, data }: disputeList = useSelector(
        (state: any) => state.disputesList.disputesList || {}
    );
    const { manageManager } = useManagerList();
    useEffect(() => {
        return () => {
            removeItem("filteringDispute");
            removeItem("filterPayload");
            removeItem("disputePage");
            removeItem("disputePageLength");
        };
    }, []);

    const handleDisputeStatus = (disputeData: any) => {
        if (
            disputeData?.status &&
            disputeData?.status?.toLowerCase() === "resolved"
        ) {
            if (disputeData?.disputeOutcome === "RESOLVED_SELLER_FAVOUR") {
                return "Won";
            } else if (disputeData?.disputeOutcome === "RESOLVED_BUYER_FAVOUR") {
                return "Lost";
            } else {
                return "Resolved";
            }
        } else {
            return disputeData?.status && disputeData?.status?.split("_").join(" ");
        }
    };


    return {
        isLoading, isSuccess, isError, data, manageManager, DisputeListColumnNames, handleDisputeStatus
    }
}
export default useDisputeList;
