import dayjs from 'dayjs';
import useOpenDatePicker from 'hooks/utilsHooks/useOpenDatePicker';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getByPspReportingListRequest } from 'redux/actions';
import { getItem, setItem } from 'utils/localStorage';
import { ReportingState } from './useByStoresReporting';

const useByPspReporting = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const tableList = 15;

  //custom hooks
  const { openDatePicker, datePickerRef } = useOpenDatePicker();
  const reportingPspState: ReportingState = useSelector(
    (state: any) => state?.reporting?.byPspReportList
  );
  const {
    isLoading,
    isSuccess,
    isError,
    data: reportingData,
  } = reportingPspState;

  // all states
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [dateFilterCheck, setDateFilterCheck] = useState(false);

  //for-payload (for all types of API call):-
  const [disputesPayload, setDisputesPayload] = useState<any>();
  const SingleAccountCheck = getItem('singleAccountCheck');
  //for date dispute filter
  useEffect(() => {
    setPage(1);
    setItem('disputePage', 1);

    if (dateFilterCheck && startDate && endDate) {
      const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
      const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');
      let datePayload = {
        ...disputesPayload,
        page: 1,
        fromDate: formattedStartDate,
        toDate: formattedEndDate,
      };
      setDisputesPayload(datePayload);
    } else if (dateFilterCheck && !startDate && !endDate) {
      let datePayload = {
        ...disputesPayload,
        page: 1,
        fromDate: null,
        toDate: null,
      };
      setDisputesPayload(datePayload);
    }
  }, [dateFilterCheck, startDate, endDate]);

  //handle dispatch for reports filter
  const handleDispatch = (payload: any) => {
    dispatch(getByPspReportingListRequest(payload));
  };

  useEffect(() => {
    if (!SingleAccountCheck) {
      setItem('disputePage', page);
      setItem('disputePageLength', tableList);
      const payload = {};
      setDisputesPayload({
        ...payload,
        ...disputesPayload,
        // page: page,
      });
    } else {
      setItem('disputePage', page);
      setItem('disputePageLength', tableList);
      const payload = {};
      setDisputesPayload({
        ...payload,
        ...disputesPayload,
        // page: page,
      });
    }
  }, []);

  //to call all API
  useEffect(() => {
    if (disputesPayload) {
      handleDispatch(disputesPayload);
    }
  }, [disputesPayload]);

  const onPageChange = (pageNumber: number) => {
    setItem('disputePage', pageNumber);
    setItem('disputePageLength', tableList);
    setPage(pageNumber);
    setDisputesPayload({
      ...disputesPayload,
      page: pageNumber,
    });
  };
  return {
    dateFilterCheck,
    disputesPayload,
    datePickerRef,
    isLoading,
    isSuccess,
    isError,
    dateRange,
    reportingData,
    endDate,
    startDate,
    page,
    tableList,
    onPageChange,
    setDisputesPayload,
    openDatePicker,
    setDateRange,
    setDateFilterCheck,
  };
};

export default useByPspReporting;
