import { handleActions } from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";

const initialState = {
    disputeResponseFindAll: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },

    disputeResponseFindByDisputeId: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },

    disputeResponseCreate: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },

    disputeResponsePdf: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },

    disputeResponsePdfByResponseId: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },

    updateStatusSubmitted: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },
    updateResponseStatusSubmitted: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    updateStatusQueued: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },
    updateStatusApproved: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },
    fetchedContent: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    savedContent: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },
    getMacros: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    getMissingMacros: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },

    savingMissingMacros: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    postUpdateStatusApproved: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },
    createDisputeResponse: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },
    deleteSingleDisputeResponse: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: ""
    }
}

//dispute-response-find-all
const getDisputeResponseFindAllRequest = (state: any, action: any) => {
    return update(state, {
        disputeResponseFindAll: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeResponseFindAllSuccess = (state: any, action: any) => {
    return update(state, {
        disputeResponseFindAll: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [...action.payload] }
        }
    });
};

const getDisputeResponseFindAllError = (state: any, action: any) => {
    return update(state, {
        disputeResponseFindAll: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-response-find-by-disputeId
const getDisputeResponseFindByDisputeIdRequest = (state: any, action: any) => {
    return update(state, {
        disputeResponseFindByDisputeId: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeResponseFindByDisputeIdSuccess = (state: any, action: any) => {
    return update(state, {
        disputeResponseFindByDisputeId: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: [...action.payload] }
        }
    });
};

const getDisputeResponseFindByDisputeIdError = (state: any, action: any) => {
    return update(state, {
        disputeResponseFindByDisputeId: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-response-create
const postDisputeResponseCreateRequest = (state: any, action: any) => {
    return update(state, {
        disputeResponseCreate: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const postDisputeResponseCreateSuccess = (state: any, action: any) => {
    return update(state, {
        disputeResponseCreate: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const postDisputeResponseCreateError = (state: any, action: any) => {
    return update(state, {
        disputeResponseCreate: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-response-pdf
const getDisputeResponsePdfRequest = (state: any, action: any) => {
    return update(state, {
        disputeResponsePdf: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeResponsePdfSuccess = (state: any, action: any) => {
    return update(state, {
        disputeResponsePdf: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getDisputeResponsePdfError = (state: any, action: any) => {
    return update(state, {
        disputeResponsePdf: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};
//dispute-response-pdf-by-responseId
const getDisputeResponsePdfByIdRequest = (state: any, action: any) => {
    return update(state, {
        disputeResponsePdfByResponseId: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeResponsePdfByIdSuccess = (state: any, action: any) => {
    return update(state, {
        disputeResponsePdfByResponseId: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getDisputeResponsePdfByIdError = (state: any, action: any) => {
    return update(state, {
        disputeResponsePdfByResponseId: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-response-STATUS_SUBMITTED
const getDisputeResponseStatusSubmittedRequest = (state: any, action: any) => {
    return update(state, {
        updateStatusSubmitted: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeResponseStatusSubmittedSuccess = (state: any, action: any) => {
    return update(state, {
        updateStatusSubmitted: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getDisputeResponseStatusSubmittedError = (state: any, action: any) => {
    return update(state, {
        updateStatusSubmitted: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};
//dispute-response-STATUS_SUBMITTED
const getDisputeUpdateStatusSubmittedRequest = (state: any, action: any) => {
    return update(state, {
        updateResponseStatusSubmitted: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const getDisputeUpdateStatusSubmittedSuccess = (state: any, action: any) => {
    return update(state, {
        updateResponseStatusSubmitted: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getDisputeUpdateStatusSubmittedError = (state: any, action: any) => {
    return update(state, {
        updateResponseStatusSubmitted: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//dispute-response-STATUS_QUEUED
const getDisputeResponseStatusQueuedRequest = (state: any, action: any) => {
    return update(state, {
        updateStatusQueued: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeResponseStatusQueuedSuccess = (state: any, action: any) => {
    return update(state, {
        updateStatusQueued: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getDisputeResponseStatusQueuedError = (state: any, action: any) => {
    return update(state, {
        updateStatusQueued: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};
//dispute-response-STATUS_APPROVED
const getDisputeResponseStatusApprovedRequest = (state: any, action: any) => {
    return update(state, {
        updateStatusApproved: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getDisputeResponseStatusApprovedSuccess = (state: any, action: any) => {
    return update(state, {
        updateStatusApproved: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getDisputeResponseStatusApprovedError = (state: any, action: any) => {
    return update(state, {
        updateStatusApproved: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};
//dispute-response-fetch-content
const getDisputeResponseFetchContentRequest = (state: any, action: any) => {
    return update(state, {
        fetchedContent: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const getDisputeResponseFetchContentSuccess = (state: any, action: any) => {
    return update(state, {
        fetchedContent: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getDisputeResponseFetchContentError = (state: any, action: any) => {
    return update(state, {
        fetchedContent: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

// dispute-response-save-content
const postDisputeResponseSaveContentRequest = (state: any, action: any) => {
    return update(state, {
        savedContent: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    })
}

const postDisputeResponseSaveContentSuccess = (state: any, action: any) => {
    return update(state, {
        savedContent: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    })
}

const postDisputeResponseSaveContentError = (state: any, action: any) => {
    return update(state, {
        savedContent: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    })
}
// dispute-response-getting-macros
const getAllMacrosRequest = (state: any, action: any) => {
    return update(state, {
        getMacros: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    })
}

const getAllMacrosSuccess = (state: any, action: any) => {
    return update(state, {
        getMacros: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    })
}

const getAllMacrosError = (state: any, action: any) => {
    return update(state, {
        getMacros: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    })
}
// dispute-response-getting-missing-macros
const getMissingMacrosRequest = (state: any, action: any) => {
    return update(state, {
        getMacros: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    })
}

const getMissingMacrosSuccess = (state: any, action: any) => {
    return update(state, {
        getMacros: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    })
}

const getMissingMacrosError = (state: any, action: any) => {
    return update(state, {
        getMacros: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    })
}

// dispute-response-getting-missing-macros
const postSavingMissingMacrosRequest = (state: any, action: any) => {
    return update(state, {
        savingMissingMacros: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    })
}

const postSavingMissingMacrosSuccess = (state: any, action: any) => {
    return update(state, {
        savingMissingMacros: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    })
}

const postSavingMissingMacrosError = (state: any, action: any) => {
    return update(state, {
        savingMissingMacros: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    })
}

// dispute-response-update-status-approved
const postDisputeReponseUpdateStatusApprovedRequest = (state: any, action: any) => {
    return update(state, {
        postUpdateStatusApproved: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    })
}
const postDisputeResponseUpdateStatusApprovedSuccess = (state: any, action: any) => {
    return update(state, {
        postUpdateStatusApproved: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    })
}
const postDisputeResponseUpdateStatusApprovedError = (state: any, action: any) => {
    return update(state, {
        postUpdateStatusApproved: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    })
}

// dispute-response-create dispute response
const postCreateResponseRequest = (state: any, action: any) => {
    return update(state, {
        createDisputeResponse: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    })
}
const postCreateResponseSuccess = (state: any, action: any) => {
    return update(state, {
        createDisputeResponse: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    })
}
const postCreateResponseError = (state: any, action: any) => {
    return update(state, {
        createDisputeResponse: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    })
}
// dispute-response-delete-Single-Dispute-Response
const deleteSingleDisputeResponseRequest = (state: any, action: any) => {
    return update(state, {
        deleteSingleDisputeResponse: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    })
}
const deleteSingleDisputeResponseSuccess = (state: any, action: any) => {
    return update(state, {
        deleteSingleDisputeResponse: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    })
}
const deleteSingleDisputeResponseError = (state: any, action: any) => {
    return update(state, {
        deleteSingleDisputeResponse: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    })
}

const deleteDisputeResponseClear = (state: any, action: any) => {
    const filteredItem = state?.disputeResponseFindByDisputeId?.data.filter((item: any) => item.id !== action.payload)
    return update(state, {
        deleteSingleDisputeResponse: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: '' },
        },
        disputeResponseFindByDisputeId: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: filteredItem }
        },
    })
}
export default handleActions(
    {
        [constants.GET_DISPUTE_RESPONSE_FIND_ALL_REQUEST]: getDisputeResponseFindAllRequest,
        [constants.GET_DISPUTE_RESPONSE_FIND_ALL_SUCCESS]: getDisputeResponseFindAllSuccess,
        [constants.GET_DISPUTE_RESPONSE_FIND_ALL_ERROR]: getDisputeResponseFindAllError,

        [constants.GET_DISPUTE_RESPONSE_FIND_BY_DISPUTE_ID_REQUEST]: getDisputeResponseFindByDisputeIdRequest,
        [constants.GET_DISPUTE_RESPONSE_FIND_BY_DISPUTE_ID_SUCCESS]: getDisputeResponseFindByDisputeIdSuccess,
        [constants.GET_DISPUTE_RESPONSE_FIND_BY_DISPUTE_ID_ERROR]: getDisputeResponseFindByDisputeIdError,

        [constants.POST_DISPUTE_RESPONSE_CREATE_REQUEST]: postDisputeResponseCreateRequest,
        [constants.POST_DISPUTE_RESPONSE_CREATE_SUCCESS]: postDisputeResponseCreateSuccess,
        [constants.POST_DISPUTE_RESPONSE_CREATE_ERROR]: postDisputeResponseCreateError,

        [constants.GET_DISPUTE_RESPONSE_PDF_REQUEST]: getDisputeResponsePdfRequest,
        [constants.GET_DISPUTE_RESPONSE_PDF_SUCCESS]: getDisputeResponsePdfSuccess,
        [constants.GET_DISPUTE_RESPONSE_PDF_ERROR]: getDisputeResponsePdfError,

        [constants.GET_DISPUTE_RESPONSE_PDF_BY_ID_REQUEST]: getDisputeResponsePdfByIdRequest,
        [constants.GET_DISPUTE_RESPONSE_PDF_BY_ID_SUCCESS]: getDisputeResponsePdfByIdSuccess,
        [constants.GET_DISPUTE_RESPONSE_PDF_BY_ID_ERROR]: getDisputeResponsePdfByIdError,

        [constants.GET_DISPUTE_RESPONSE_STATUS_SUBMITTED_REQUEST]: getDisputeResponseStatusSubmittedRequest,
        [constants.GET_DISPUTE_RESPONSE_STATUS_SUBMITTED_SUCCESS]: getDisputeResponseStatusSubmittedSuccess,
        [constants.GET_DISPUTE_RESPONSE_STATUS_SUBMITTED_ERROR]: getDisputeResponseStatusSubmittedError,

        [constants.GET_DISPUTE_UPDATE_RESPONSE_STATUS_SUBMITTED_REQUEST]: getDisputeUpdateStatusSubmittedRequest,
        [constants.GET_DISPUTE_UPDATE_RESPONSE_STATUS_SUBMITTED_SUCCESS]: getDisputeUpdateStatusSubmittedSuccess,
        [constants.GET_DISPUTE_UPDATE_RESPONSE_STATUS_SUBMITTED_ERROR]: getDisputeUpdateStatusSubmittedError,

        [constants.GET_DISPUTE_RESPONSE_STATUS_QUEUED_REQUEST]: getDisputeResponseStatusQueuedRequest,
        [constants.GET_DISPUTE_RESPONSE_STATUS_QUEUED_SUCCESS]: getDisputeResponseStatusQueuedSuccess,
        [constants.GET_DISPUTE_RESPONSE_STATUS_QUEUED_ERROR]: getDisputeResponseStatusQueuedError,

        [constants.GET_DISPUTE_RESPONSE_STATUS_APPROVED_REQUEST]: getDisputeResponseStatusApprovedRequest,
        [constants.GET_DISPUTE_RESPONSE_STATUS_APPROVED_SUCCESS]: getDisputeResponseStatusApprovedSuccess,
        [constants.GET_DISPUTE_RESPONSE_STATUS_APPROVED_ERROR]: getDisputeResponseStatusApprovedError,

        [constants.GET_DISPUTE_RESPONSE_FETCH_CONTENT_REQUEST]: getDisputeResponseFetchContentRequest,
        [constants.GET_DISPUTE_RESPONSE_FETCH_CONTENT_SUCCESS]: getDisputeResponseFetchContentSuccess,
        [constants.GET_DISPUTE_RESPONSE_FETCH_CONTENT_ERROR]: getDisputeResponseFetchContentError,

        [constants.POST_DISPUTE_RESPONSE_SAVE_CONTENT_REQUEST]: postDisputeResponseSaveContentRequest,
        [constants.POST_DISPUTE_RESPONSE_SAVE_CONTENT_SUCCESS]: postDisputeResponseSaveContentSuccess,
        [constants.POST_DISPUTE_RESPONSE_SAVE_CONTENT_ERROR]: postDisputeResponseSaveContentError,

        [constants.GET_ALL_MACROS_REQUEST]: getAllMacrosRequest,
        [constants.GET_ALL_MACROS_SUCCESS]: getAllMacrosSuccess,
        [constants.GET_ALL_MACROS_ERROR]: getAllMacrosError,

        [constants.GET_MISSING_MACROS_REQUEST]: getMissingMacrosRequest,
        [constants.GET_MISSING_MACROS_SUCCESS]: getMissingMacrosSuccess,
        [constants.GET_MISSING_MACROS_ERROR]: getMissingMacrosError,

        [constants.POST_SAVING_MISSING_MACROS_REQUEST]: postSavingMissingMacrosRequest,
        [constants.POST_SAVING_MISSING_MACROS_SUCCESS]: postSavingMissingMacrosSuccess,
        [constants.POST_SAVING_MISSING_MACROS_ERROR]: postSavingMissingMacrosError,

        [constants.POST_DISPUTE_RESPONSE_UPDATE_STATUS_REQUEST]: postDisputeReponseUpdateStatusApprovedRequest,
        [constants.POST_DISPUTE_RESPONSE_UPDATE_STATUS_SUCCESS]: postDisputeResponseUpdateStatusApprovedSuccess,
        [constants.POST_DISPUTE_RESPONSE_UPDATE_STATUS_ERROR]: postDisputeResponseUpdateStatusApprovedError,

        [constants.POST_CREATE_RESPONSE_REQUEST]: postCreateResponseRequest,
        [constants.POST_CREATE_RESPONSE_SUCCESS]: postCreateResponseSuccess,
        [constants.POST_CREATE_RESPONSE_ERROR]: postCreateResponseError,

        [constants.DELETE_DISPUTE_RESPONSE_REQUEST]: deleteSingleDisputeResponseRequest,
        [constants.DELETE_DISPUTE_RESPONSE_SUCCESS]: deleteSingleDisputeResponseSuccess,
        [constants.DELETE_DISPUTE_RESPONSE_ERROR]: deleteSingleDisputeResponseError,
        [constants.DELETE_DISPUTE_RESPONSE_CLEAR]: deleteDisputeResponseClear,
    },
    initialState
);