// Function to generate the URL string
export function generateURL(payload: any) {
  let urlString = `/dispute/find-filtered-disputes?page=${payload?.page}&pageLength=${payload?.pageLength}`;
  if (payload?.statuses && payload?.statuses.length > 0) {
    payload?.statuses.forEach((status: string) => {
      if (status === 'ACTIVE' || status === 'INACTIVE') {
        urlString += `&chargeActive=${status === 'ACTIVE'}`;
      } else {
        urlString += `&statuses=${encodeURIComponent(status)}`;
      }
    });
  }
  // Add carriers to the URL string
  if (payload?.carriers && payload?.carriers.length > 0) {
    payload?.carriers.forEach((status: string) => {
      urlString += `&carriers=${encodeURIComponent(status)}`;
    });
  }
  // Add stores to the URL string
  if (payload?.shopIds && payload?.shopIds.length > 0) {
    payload?.shopIds.forEach((status: string) => {
      urlString += `&shopIds=${encodeURIComponent(status)}`;
    });
  }
  // Add reasons to the URL string
  if (payload?.reasons && payload?.reasons.length > 0) {
    payload.reasons.forEach((status: string) => {
      urlString += `&reasons=${encodeURIComponent(status)}`;
    });
  }
  // add dispute response status to the URL string
  if (
    payload?.disputeResponseStatus &&
    payload?.disputeResponseStatus.length > 0
  ) {
    payload.disputeResponseStatus.forEach((status: string) => {
      urlString += `&disputeResponseStatus=${encodeURIComponent(status)}`;
    });
  }
  // Add reasons to the stage string
  if (payload?.stage && payload?.stage.length > 0) {
    payload.stage.forEach((status: string) => {
      urlString += `&stage=${encodeURIComponent(status)}`;
    });
  }
  // Adding paymentProcessor box data to the url string
  if (payload?.paymentProcessor && payload?.paymentProcessor.length > 0) {
    payload.paymentProcessor.forEach((status: string) => {
      urlString += `&paymentProcessor=${encodeURIComponent(status)}`;
    });
  }

  //  code for adding carriers:
  if (payload?.carriers && payload?.carriers.length > 0) {
    payload.carriers.forEach((carrier: string) => {
      urlString += `&carriers=${encodeURIComponent(carrier)}`;
    });
  }
  // Added oneDay, oneWeek, oneMonth parameters as needed

  //  code for adding oneDay:
  if (payload?.oneDay) {
    urlString += '&oneDay=true';
  } else {
    urlString += '&oneDay=false';
  }

  //  code for adding oneWeek:
  if (payload?.oneWeek) {
    urlString += '&oneWeek=true';
  } else {
    urlString += '&oneWeek=false';
  }

  //  code for adding oneMonth:
  if (payload?.oneMonth) {
    urlString += '&oneMonth=true';
  } else {
    urlString += '&oneMonth=false';
  }
  // Adding Search box data to the url string
  if (payload?.search) {
    urlString += `&search=${payload.search.trim()}`;
  }
  // Adding disputeOutcome box data to the url string
  if (payload?.disputeOutcome) {
    urlString += `&disputeOutcome=${payload.disputeOutcome.trim()}`;
  }
  if (payload?.fromDate) {
    urlString += `&fromDate=${payload.fromDate}`;
  }
  if (payload?.toDate) {
    urlString += `&toDate=${payload.toDate}`;
  }

  return urlString;
}
export function generateReportingURL(payload: any) {
  let urlString = `/dispute/report?`;
  // Add stores to the URL string
  if (payload?.shopIds && payload?.shopIds.length > 0) {
    payload?.shopIds.forEach((status: string) => {
      urlString += `&shopId=${encodeURIComponent(status)}`;
    });
  }
  // for handle statuses
  if (payload?.statuses && payload?.statuses.length > 0) {
    payload?.statuses.forEach((status: string) => {
      if (status === 'ACTIVE' || status === 'INACTIVE') {
        urlString += `&chargePay=${status === 'ACTIVE'}`;
      } else if (status === 'WON' || status === 'LOST') {
        urlString += `&disputeOutcome=${
          status === 'WON' ? 'RESOLVED_SELLER_FAVOUR' : 'RESOLVED_BUYER_FAVOUR'
        }`;
      } else if (
        status === 'HANDLED BY CHARGEPAY' ||
        status !== 'HANDLED BY CHARGEPAY'
      ) {
        urlString += `&submittedByChargePay=${
          status === 'HANDLED BY CHARGEPAY'
        }`;
      } else {
        urlString += `&statuses=${encodeURIComponent(status)}`;
      }
    });
  }
  // Add payment processors to the URL string
  if (payload?.paymentProcessor && payload?.paymentProcessor.length > 0) {
    payload?.paymentProcessor.forEach((status: string) => {
      urlString += `&paymentProcessor=${encodeURIComponent(status)}`;
    });
  }
  if (payload?.reason && payload?.reason.length > 0) {
    payload?.reason.forEach((status: string) => {
      urlString += `&reason=${encodeURIComponent(status)}`;
    });
  }
  // Add disputeOutcome to the URL string
  if (payload?.disputeOutcome && payload?.disputeOutcome.length > 0) {
    payload?.disputeOutcome.forEach((status: string) => {
      urlString += `&disputeOutcome=${encodeURIComponent(status)}`;
    });
  }
  // Add disputeOutcome to the URL string
  if (payload?.submittedByChargePay) {
    urlString += `&submittedByChargePay=${payload?.submittedByChargePay}`;
  }
  // add date filter to the URL string
  if (payload?.fromDate) {
    urlString += `&decisionDateFrom=${payload.fromDate}`;
  }
  if (payload?.toDate) {
    urlString += `&decisionDateTo=${payload.toDate}`;
  }
  return urlString;
}

export function generateUnderReviewURL(payload: any) {
  let urlString = `dispute/fetch-under-review-disputes?page=${payload?.page}&pageLength=${payload?.pageLength}`;

  // Add stores to the URL string
  if (payload?.shopIds && payload?.shopIds.length > 0) {
    payload?.shopIds.forEach((status: string) => {
      urlString += `&shopIds=${encodeURIComponent(status)}`;
    });
  }

  // for handling paymentProcessors
  if (payload?.paymentProcessors && payload?.paymentProcessors.length > 0) {
    payload.paymentProcessors.forEach((processor: string) => {
      switch (processor) {
        case 'PAYPAL':
          urlString += `&paymentProcessors=PayPal`;
          break;
        case 'STRIPE':
          urlString += `&paymentProcessors=Stripe`;
          break;
        case 'SHOPIFY PAYMENTS':
          urlString += `&paymentProcessors=ShopifyPayments`;
          break;
        case 'BRAIN TREE':
          urlString += `&paymentProcessors=BrainTree`;
          break;
        case 'KLARNA':
          urlString += `&paymentProcessors=Klarna`;
          break;
        default:
          urlString += `&paymentProcessors=${encodeURIComponent(processor)}`;
          break;
      }
    });
  }
  return urlString;
}
export function generateUnderReviewCardTypeURL(payload: any) {
  let urlString = ``;

  if (payload?.cardType === 'totalAmount') {
    urlString = `dispute/underReview-total-amount?`;
  } else if (payload?.cardType === 'totalBilledAmount') {
    urlString = `dispute/underReview-total-billedAmount?`;
  }

  // Add stores to the URL string
  if (payload?.shopIds && payload?.shopIds.length > 0) {
    payload?.shopIds.forEach((status: string) => {
      urlString += `&shopIds=${encodeURIComponent(status)}`;
    });
  }

  // for handling paymentProcessors
  if (payload?.paymentProcessors && payload?.paymentProcessors.length > 0) {
    payload.paymentProcessors.forEach((processor: string) => {
      switch (processor) {
        case 'PAYPAL':
          urlString += `&paymentProcessors=PayPal`;
          break;
        case 'STRIPE':
          urlString += `&paymentProcessors=Stripe`;
          break;
        case 'SHOPIFY PAYMENTS':
          urlString += `&paymentProcessors=ShopifyPayments`;
          break;
        case 'BRAIN TREE':
          urlString += `&paymentProcessors=BrainTree`;
          break;
        case 'KLARNA':
          urlString += `&paymentProcessors=Klarna`;
          break;
        default:
          urlString += `&paymentProcessors=${encodeURIComponent(processor)}`;
          break;
      }
    });
  }
  return urlString;
}

export function generateWaitingSellerResponseURL(payload: any) {
  let urlString = `dispute/fetch-awaiting-disputes?page=${payload?.page}&pageLength=${payload?.pageLength}`;

  // add reasons to the URL string
  if (payload?.reason && payload?.reason.length > 0) {
    payload?.reason.forEach((status: string) => {
      urlString += `&reason=${encodeURIComponent(status)}`;
    });
  }

  // for handling paymentProcessors
  if (payload?.paymentProcessors && payload?.paymentProcessors.length > 0) {
    payload.paymentProcessors.forEach((processor: string) => {
      switch (processor) {
        case 'PAYPAL':
          urlString += `&paymentProcessor=PayPal`;
          break;
        case 'STRIPE':
          urlString += `&paymentProcessor=Stripe`;
          break;
        case 'SHOPIFY PAYMENTS':
          urlString += `&paymentProcessor=ShopifyPayments`;
          break;
        case 'BRAIN TREE':
          urlString += `&paymentProcessor=BrainTree`;
          break;
        case 'KLARNA':
          urlString += `&paymentProcessor=Klarna`;
          break;
        default:
          urlString += `&paymentProcessor=${encodeURIComponent(processor)}`;
          break;
      }
    });
  }
  return urlString;
}

export function generateWaitingSellerCardAmountURL(payload: any) {
  let urlString = ``;
  if (payload?.cardType === 'totalAmount') {
    urlString = `dispute/awaiting-total-amount?`;
  } else if (payload?.cardType === 'totalBilledAmount') {
    urlString = `dispute/awaiting-total-billedAmount?`;
  }
  // add reasons to the URL string
  if (payload?.reason && payload?.reason.length > 0) {
    payload?.reason.forEach((status: string) => {
      urlString += `&reason=${encodeURIComponent(status)}`;
    });
  }

  // for handling paymentProcessors
  if (payload?.paymentProcessors && payload?.paymentProcessors.length > 0) {
    payload.paymentProcessors.forEach((processor: string) => {
      switch (processor) {
        case 'PAYPAL':
          urlString += `&paymentProcessor=PayPal`;
          break;
        case 'STRIPE':
          urlString += `&paymentProcessor=Stripe`;
          break;
        case 'SHOPIFY PAYMENTS':
          urlString += `&paymentProcessor=ShopifyPayments`;
          break;
        case 'BRAIN TREE':
          urlString += `&paymentProcessor=BrainTree`;
          break;
        case 'KLARNA':
          urlString += `&paymentProcessor=Klarna`;
          break;
        default:
          urlString += `&paymentProcessor=${encodeURIComponent(processor)}`;
          break;
      }
    });
  }
  return urlString;
}

export function generateReadyDisputeURL(payload: any) {
  let urlString = `/dispute/ready-dispute?page=${payload?.page}&pageLength=${payload?.pageLength}`;
  if (payload?.shopIds && payload?.shopIds.length > 0) {
    payload?.shopIds.forEach((status: string) => {
      urlString += `&shopId=${encodeURIComponent(status)}`;
    });
  }
  // if (payload?.pageLength) {
  //   urlString += `&pageLength=${encodeURIComponent(payload?.pageLength)}`;
  // }
  // if (payload?.page) {
  //   urlString += `&page=${encodeURIComponent(payload?.page)}`;
  // }
  return urlString;
}
export function generateReadyDisputeURLTotalBilledAmount(payload: any) {
  let urlString = `/dispute/new-billing/total-billed-amount?`;
  if (payload?.shopIds && payload?.shopIds.length > 0) {
    payload?.shopIds.forEach((status: string) => {
      urlString += `&shopId=${encodeURIComponent(status)}`;
    });
  }
  // if (payload?.pageLength) {
  //   urlString += `&pageLength=${encodeURIComponent(payload?.pageLength)}`;
  // }
  // if (payload?.page) {
  //   urlString += `&page=${encodeURIComponent(payload?.page)}`;
  // }
  return urlString;
}
export function generateReadyDisputeURLTotalAmount(payload: any) {
  let urlString = `/dispute/new-billing/total-amount?`;
  if (payload?.shopIds && payload?.shopIds.length > 0) {
    payload?.shopIds.forEach((status: string) => {
      urlString += `&shopId=${encodeURIComponent(status)}`;
    });
  }
  // if (payload?.pageLength) {
  //   urlString += `&pageLength=${encodeURIComponent(payload?.pageLength)}`;
  // }
  // if (payload?.page) {
  //   urlString += `&page=${encodeURIComponent(payload?.page)}`;
  // }
  return urlString;
}



export function generateByReasonReportingURL(payload: any) {
  let urlString = `/dispute/by-reason`;
  // add date filter to the URL string
  if (payload?.fromDate) {
    urlString += `&decisionDateFrom=${payload.fromDate}`;
  }
  if (payload?.toDate) {
    urlString += `&decisionDateTo=${payload.toDate}`;
  }
  return urlString;
}
export function generateByMonthlyReportingURL(payload: any) {
  // let urlString = `/dispute/monthly-report`;
  let urlString = `/dispute/monthly-report?page=${payload?.page}&pageLength=${payload?.pageLength}`;
  // add date filter to the URL string
  if (payload?.fromDate) {
    urlString += `&decisionDateFrom=${payload.fromDate}`;
  }
  if (payload?.toDate) {
    urlString += `&decisionDateTo=${payload.toDate}`;
  }
  if (payload?.submittedByChargePay){
    urlString+= `&submittedByChargePay=${payload?.submittedByChargePay}`
  }
  return urlString;
}
export function generateByStoresReportingURL(payload: any) {
  let urlString = `/dispute/by-stores`;
  // add date filter to the URL string
  if (payload?.fromDate) {
    urlString += `&decisionDateFrom=${payload.fromDate}`;
  }
  if (payload?.toDate) {
    urlString += `&decisionDateTo=${payload.toDate}`;
  }
  return urlString;
}
export function generateByPspReportingURL(payload: any) {
  let urlString = `/dispute/by-psp`;
  // add date filter to the URL string
  if (payload?.fromDate) {
    urlString += `&decisionDateFrom=${payload.fromDate}`;
  }
  if (payload?.toDate) {
    urlString += `&decisionDateTo=${payload.toDate}`;
  }
  return urlString;
}
