import { createAction } from 'redux-actions';
import * as constants from './constants'

export const postCreateUserRequest = createAction(constants.POST_CREATE_USER_REQUEST);
export const postCreateUserSuccess = createAction(constants.POST_CREATE_USER_SUCCESS);
export const postCreateUserError = createAction(constants.POST_CREATE_USER_ERROR);

export const postConnectPayPalRequest = createAction(constants.POST_CONNECT_PAYPAL_REQUEST);
export const postConnectPayPalSuccess = createAction(constants.POST_CONNECT_PAYPAL_SUCCESS);
export const postConnectPayPalError = createAction(constants.POST_CONNECT_PAYPAL_ERROR);

export const getTotalRevenueProtectedRequest = createAction(constants.GET_TOTAL_REVENUE_PROTECTED_REQUEST);
export const getTotalRevenueProtectedSuccess = createAction(constants.GET_TOTAL_REVENUE_PROTECTED_SUCCESS);
export const getTotalRevenueProtectedError = createAction(constants.GET_TOTAL_REVENUE_PROTECTED_ERROR);

export const getTotalRevenueProtectedByAccountRequest = createAction(constants.GET_TOTAL_REVENUE_PROTECTED_BY_ACCOUNT_REQUEST);
export const getTotalRevenueProtectedByAccountSuccess = createAction(constants.GET_TOTAL_REVENUE_PROTECTED_BY_ACCOUNT_SUCCESS);
export const getTotalRevenueProtectedByAccountError = createAction(constants.GET_TOTAL_REVENUE_PROTECTED_BY_ACCOUNT_ERROR);

export const getLinkedShopsRequest = createAction(constants.GET_LINKED_SHOPS_REQUEST);
export const getLinkedShopsSuccess = createAction(constants.GET_LINKED_SHOPS_SUCCESS);
export const getLinkedShopsError = createAction(constants.GET_LINKED_SHOPS_ERROR);

export const getLinkedShopsByAccountRequest = createAction(constants.GET_LINKED_SHOPS_BY_ACCOUNT_REQUEST);
export const getLinkedShopsByAccountSuccess = createAction(constants.GET_LINKED_SHOPS_BY_ACCOUNT_SUCCESS);
export const getLinkedShopsByAccountError = createAction(constants.GET_LINKED_SHOPS_BY_ACCOUNT_ERROR);

export const deleteLinkedShopsRequest = createAction(constants.DELETE_LINKED_SHOPS_REQUEST);
export const deleteLinkedShopsSuccess = createAction(constants.DELETE_LINKED_SHOPS_SUCCESS);
export const deleteLinkedShopsError = createAction(constants.DELETE_LINKED_SHOPS_ERROR);

export const getLinkedPayPalRequest = createAction(constants.GET_LINKED_PAYPAL_REQUEST);
export const getLinkedPayPalSuccess = createAction(constants.GET_LINKED_PAYPAL_SUCCESS);
export const getLinkedPayPalError = createAction(constants.GET_LINKED_PAYPAL_ERROR);

export const getLinkedPayPalByAccountRequest = createAction(constants.GET_LINKED_PAYPAL_BY_ACCOUNT_REQUEST);
export const getLinkedPayPalByAccountSuccess = createAction(constants.GET_LINKED_PAYPAL_BY_ACCOUNT_SUCCESS);
export const getLinkedPayPalByAccountError = createAction(constants.GET_LINKED_PAYPAL_BY_ACCOUNT_ERROR);

export const getRecentDisputesRequest = createAction(constants.GET_RECENT_DISPUTES_REQUEST);
export const getRecentDisputesSuccess = createAction(constants.GET_RECENT_DISPUTES_SUCCESS);
export const getRecentDisputesError = createAction(constants.GET_RECENT_DISPUTES_ERROR);

export const getRecentDisputesByAccountRequest = createAction(constants.GET_RECENT_DISPUTES_BY_ACCOUNT_REQUEST);
export const getRecentDisputesByAccountSuccess = createAction(constants.GET_RECENT_DISPUTES_BY_ACCOUNT_SUCCESS);
export const getRecentDisputesByAccountError = createAction(constants.GET_RECENT_DISPUTES_BY_ACCOUNT_ERROR);

export const getOpenDisputesRequest = createAction(constants.GET_OPEN_DISPUTES_REQUEST);
export const getOpenDisputesSuccess = createAction(constants.GET_OPEN_DISPUTES_SUCCESS);
export const getOpenDisputesError = createAction(constants.GET_OPEN_DISPUTES_ERROR);

export const getOpenDisputesByAccountRequest = createAction(constants.GET_OPEN_DISPUTES_BY_ACCOUNT_REQUEST);
export const getOpenDisputesByAccountSuccess = createAction(constants.GET_OPEN_DISPUTES_BY_ACCOUNT_SUCCESS);
export const getOpenDisputesByAccountError = createAction(constants.GET_OPEN_DISPUTES_BY_ACCOUNT_ERROR);

export const getRecoveredDisputesRequest = createAction(constants.GET_RECOVERED_DISPUTES_REQUEST);
export const getRecoveredDisputesSuccess = createAction(constants.GET_RECOVERED_DISPUTES_SUCCESS);
export const getRecoveredDisputesError = createAction(constants.GET_RECOVERED_DISPUTES_ERROR);

export const getRecoveredDisputesByAccountRequest = createAction(constants.GET_RECOVERED_DISPUTES_BY_ACCOUNT_REQUEST);
export const getRecoveredDisputesByAccountSuccess = createAction(constants.GET_RECOVERED_DISPUTES_BY_ACCOUNT_SUCCESS);
export const getRecoveredDisputesByAccountError = createAction(constants.GET_RECOVERED_DISPUTES_BY_ACCOUNT_ERROR);

export const getCurrentWeekDisputesRequest = createAction(constants.GET_CURRENT_WEEK_DISPUTES_REQUEST);
export const getCurrentWeekDisputesSuccess = createAction(constants.GET_CURRENT_WEEK_DISPUTES_SUCCESS);
export const getCurrentWeekDisputesError = createAction(constants.GET_CURRENT_WEEK_DISPUTES_ERROR);

export const getCurrentWeekDisputesByAccountRequest = createAction(constants.GET_CURRENT_WEEK_DISPUTES_BY_ACCOUNT_REQUEST);
export const getCurrentWeekDisputesByAccountSuccess = createAction(constants.GET_CURRENT_WEEK_DISPUTES_BY_ACCOUNT_SUCCESS);
export const getCurrentWeekDisputesByAccountError = createAction(constants.GET_CURRENT_WEEK_DISPUTES_BY_ACCOUNT_ERROR);

export const getDisputesListRequest = createAction(constants.GET_DISPUTES_LIST_REQUEST);
export const getDisputesListSuccess = createAction(constants.GET_DISPUTES_LIST_SUCCESS);
export const getDisputesListError = createAction(constants.GET_DISPUTES_LIST_ERROR);

export const getFilteredDisputesRequest = createAction(constants.GET_FILTERED_DISPUTES_REQUEST);
export const getFilteredDisputesSuccess = createAction(constants.GET_FILTERED_DISPUTES_SUCCESS);
export const getFilteredDisputesError = createAction(constants.GET_FILTERED_DISPUTES_ERROR);

export const postDisputesListFetchByAccountRequest = createAction(constants.POST_DISPUTES_LIST_FETCH_BY_ACCOUNT_REQUEST);
export const postDisputesListFetchByAccountSuccess = createAction(constants.POST_DISPUTES_LIST_FETCH_BY_ACCOUNT_SUCCESS);
export const postDisputesListFetchByAccountError = createAction(constants.POST_DISPUTES_LIST_FETCH_BY_ACCOUNT_ERROR);

export const getProcessingDisputesRequest = createAction(constants.GET_PROCESSING_DISPUTES_REQUEST);
export const getProcessingDisputesSuccess = createAction(constants.GET_PROCESSING_DISPUTES_SUCCESS);
export const getProcessingDisputesError = createAction(constants.GET_PROCESSING_DISPUTES_ERROR);

export const getProcessingDisputesByAccountRequest = createAction(constants.GET_PROCESSING_DISPUTES_BY_ACCOUNT_REQUEST);
export const getProcessingDisputesByAccountSuccess = createAction(constants.GET_PROCESSING_DISPUTES_BY_ACCOUNT_SUCCESS);
export const getProcessingDisputesByAccountError = createAction(constants.GET_PROCESSING_DISPUTES_BY_ACCOUNT_ERROR);

export const getAwaitingResponseDisputesRequest = createAction(constants.GET_AWAITING_RESPONSE_DISPUTES_REQUEST);
export const getAwaitingResponseDisputesSuccess = createAction(constants.GET_AWAITING_RESPONSE_DISPUTES_SUCCESS);
export const getAwaitingResponseDisputesError = createAction(constants.GET_AWAITING_RESPONSE_DISPUTES_ERROR);

export const getAwaitingResponseDisputesByAccountRequest = createAction(constants.GET_AWAITING_RESPONSE_DISPUTES_BY_ACCOUNT_REQUEST);
export const getAwaitingResponseDisputesByAccountSuccess = createAction(constants.GET_AWAITING_RESPONSE_DISPUTES_BY_ACCOUNT_SUCCESS);
export const getAwaitingResponseDisputesByAccountError = createAction(constants.GET_AWAITING_RESPONSE_DISPUTES_BY_ACCOUNT_ERROR);

//dispute-details
export const getDisputeDetailsRequest = createAction(constants.GET_DISPUTE_DETAILS_REQUEST);
export const getDisputeDetailsSuccess = createAction(constants.GET_DISPUTE_DETAILS_SUCCESS);
export const getDisputeDetailsError = createAction(constants.GET_DISPUTE_DETAILS_ERROR);

export const getDisputeOrderSummaryRequest = createAction(constants.GET_DISPUTE_ORDER_SUMMARY_REQUEST);
export const getDisputeOrderSummarySuccess = createAction(constants.GET_DISPUTE_ORDER_SUMMARY_SUCCESS);
export const getDisputeOrderSummaryError = createAction(constants.GET_DISPUTE_ORDER_SUMMARY_ERROR);

export const getDisputeTransactionDetailsRequest = createAction(constants.GET_DISPUTE_TRANSACTION_DETAILS_REQUEST);
export const getDisputeTransactionDetailsSuccess = createAction(constants.GET_DISPUTE_TRANSACTION_DETAILS_SUCCESS);
export const getDisputeTransactionDetailsError = createAction(constants.GET_DISPUTE_TRANSACTION_DETAILS_ERROR);

export const getDisputeCustomerDetailsRequest = createAction(constants.GET_DISPUTE_CUSTOMER_DETAILS_REQUEST);
export const getDisputeCustomerDetailsSuccess = createAction(constants.GET_DISPUTE_CUSTOMER_DETAILS_SUCCESS);
export const getDisputeCustomerDetailsError = createAction(constants.GET_DISPUTE_CUSTOMER_DETAILS_ERROR);

export const getDisputeJiraCommentsRequest = createAction(constants.GET_DISPUTE_JIRA_CUSTOMERS_REQUEST);
export const getDisputeJiraCommentsSuccess = createAction(constants.GET_DISPUTE_JIRA_CUSTOMERS_SUCCESS);
export const getDisputeJiraCommentsError = createAction(constants.GET_DISPUTE_JIRA_CUSTOMERS_ERROR);

export const getDisputeTrackingDetailsRequest = createAction(constants.GET_DISPUTE_TRACKING_DETAILS_REQUEST);
export const getDisputeTrackingDetailsSuccess = createAction(constants.GET_DISPUTE_TRACKING_DETAILS_SUCCESS);
export const getDisputeTrackingDetailsError = createAction(constants.GET_DISPUTE_TRACKING_DETAILS_ERROR);

export const getDisputeResponseJsonByIdRequest = createAction(constants.GET_RESPONSE_JSON_BY_ID_REQUEST);
export const getDisputeResponseJsonByIdSuccess = createAction(constants.GET_RESPONSE_JSON_BY_ID_SUCCESS);
export const getDisputeResponseJsonByIdError = createAction(constants.GET_RESPONSE_JSON_BY_ID_ERROR);

export const getDisputeFraudAnalysisRequest = createAction(constants.GET_DISPUTE_FRAUD_ANALYSIS_DETAILS_REQUEST);
export const getDisputeFraudAnalysisSuccess = createAction(constants.GET_DISPUTE_FRAUD_ANALYSIS_DETAILS_SUCCESS);
export const getDisputeFraudAnalysisError = createAction(constants.GET_DISPUTE_FRAUD_ANALYSIS_DETAILS_ERROR);

export const getDisputeTrackingRequest = createAction(constants.GET_DISPUTE_TRACKING_REQUEST);
export const getDisputeTrackingSuccess = createAction(constants.GET_DISPUTE_TRACKING_SUCCESS);
export const getDisputeTrackingError = createAction(constants.GET_DISPUTE_TRACKING_ERROR);

export const getDisputeShippingDetailsRequest = createAction(constants.GET_DISPUTE_SHIPPING_DETAILS_REQUEST);
export const getDisputeShippingDetailsSuccess = createAction(constants.GET_DISPUTE_SHIPPING_DETAILS_SUCCESS);
export const getDisputeShippingDetailsError = createAction(constants.GET_DISPUTE_SHIPPING_DETAILS_ERROR);

export const postDisputeSubmittedRequest = createAction(constants.POST_DISPUTE_SUBMITTED_REQUEST);
export const postDisputeSubmittedSuccess = createAction(constants.POST_DISPUTE_SUBMITTED_SUCCESS);
export const postDisputeSubmittedError = createAction(constants.POST_DISPUTE_SUBMITTED_ERROR);

export const getDisputeOrderFulfillmentDetailsRequest = createAction(constants.GET_DISPUTE_FULFILLMENT_DETAILS_REQUEST);
export const getDisputeOrderFulfillmentDetailsSuccess = createAction(constants.GET_DISPUTE_FULFILLMENT_DETAILS_SUCCESS);
export const getDisputeOrderFulfillmentDetailsError = createAction(constants.GET_DISPUTE_FULFILLMENT_DETAILS_ERROR);

export const getDisputeEvidenceDetailsRequest = createAction(constants.GET_DISPUTE_EVIDENCE_DETAILS_REQUEST);
export const getDisputeEvidenceDetailsSuccess = createAction(constants.GET_DISPUTE_EVIDENCE_DETAILS_SUCCESS);
export const getDisputeEvidenceDetailsError = createAction(constants.GET_DISPUTE_EVIDENCE_DETAILS_ERROR);

export const getDisputeMessageDetailsRequest = createAction(constants.GET_DISPUTE_MESSAGE_DETAILS_REQUEST);
export const getDisputeMessageDetailsSuccess = createAction(constants.GET_DISPUTE_MESSAGE_DETAILS_SUCCESS);
export const getDisputeMessageDetailsError = createAction(constants.GET_DISPUTE_MESSAGE_DETAILS_ERROR);

export const getDisputeAdjudicationDetailsRequest = createAction(constants.GET_DISPUTE_ADJUDICATION_DETAILS_REQUEST);
export const getDisputeAdjudicationDetailsSuccess = createAction(constants.GET_DISPUTE_ADJUDICATION_DETAILS_SUCCESS);
export const getDisputeAdjudicationDetailsError = createAction(constants.GET_DISPUTE_ADJUDICATION_DETAILS_ERROR);

export const getDisputeMoneyMovementDetailsRequest = createAction(constants.GET_DISPUTE_MONEY_MOVEMENT_DETAILS_REQUEST);
export const getDisputeMoneyMovementDetailsSuccess = createAction(constants.GET_DISPUTE_MONEY_MOVEMENT_DETAILS_SUCCESS);
export const getDisputeMoneyMovementDetailsError = createAction(constants.GET_DISPUTE_MONEY_MOVEMENT_DETAILS_ERROR);

export const getDisputeSupportingInfoDetailsRequest = createAction(constants.GET_DISPUTE_SUPPORTING_INFO_DETAILS_REQUEST);
export const getDisputeSupportingInfoDetailsSuccess = createAction(constants.GET_DISPUTE_SUPPORTING_INFO_DETAILS_SUCCESS);
export const getDisputeSupportingInfoDetailsError = createAction(constants.GET_DISPUTE_SUPPORTING_INFO_DETAILS_ERROR);

export const getManageDisputeFlowRequest = createAction(constants.GET_DISPUTE_MANAGE_FLOW_REQUEST);
export const getManageDisputeFlowSuccess = createAction(constants.GET_DISPUTE_MANAGE_FLOW_SUCCESS);
export const getManageDisputeFlowError = createAction(constants.GET_DISPUTE_MANAGE_FLOW_ERROR);

export const getFetchDisputeTemplateRequest = createAction(constants.GET_FETCH_DISPUTE_TEMPLATE_REQUEST);
export const getFetchDisputeTemplateSuccess = createAction(constants.GET_FETCH_DISPUTE_TEMPLATE_SUCCESS);
export const getFetchDisputeTemplateError = createAction(constants.GET_FETCH_DISPUTE_TEMPLATE_ERROR);

export const getFetchTemplateSummaryRequest = createAction(constants.GET_FETCH_DISPUTE_SUMMARY_REQUEST);
export const getFetchTemplateSummarySuccess = createAction(constants.GET_FETCH_DISPUTE_SUMMARY_SUCCESS);
export const getFetchTemplateSummaryError = createAction(constants.GET_FETCH_DISPUTE_SUMMARY_ERROR);

export const getDisputeOfferDetailsRequest = createAction(constants.GET_DISPUTE_OFFER_DETAILS_REQUEST);
export const getDisputeOfferDetailsSuccess = createAction(constants.GET_DISPUTE_OFFER_DETAILS_SUCCESS);
export const getDisputeOfferDetailsError = createAction(constants.GET_DISPUTE_OFFER_DETAILS_ERROR);

export const postDisputeFlowUploadFileRequest = createAction(constants.POST_DISPUTE_FLOW_UPLOAD_FILE_REQUEST);
export const postDisputeFlowUploadFileSuccess = createAction(constants.POST_DISPUTE_FLOW_UPLOAD_FILE_SUCCESS);
export const postDisputeFlowUploadFileError = createAction(constants.POST_DISPUTE_FLOW_UPLOAD_FILE_ERROR);

export const postDisputeSendMessageRequest = createAction(constants.POST_DISPUTE_SEND_MESSAGE_REQUEST);
export const postDisputeSendMessageSuccess = createAction(constants.POST_DISPUTE_SEND_MESSAGE_SUCCESS);
export const postDisputeSendMessageError = createAction(constants.POST_DISPUTE_SEND_MESSAGE_ERROR);

export const postAccountListRequest = createAction(constants.POST_ACCOUNT_LIST_REQUEST);
export const postAccountListSuccess = createAction(constants.POST_ACCOUNT_LIST_SUCCESS);
export const postAccountListError = createAction(constants.POST_ACCOUNT_LIST_ERROR);

//manage-dispute-details-actions
export const postManageDisputeSendMessageRequest = createAction(constants.POST_MANAGE_DISPUTE_SEND_MESSAGE_REQUEST);
export const postManageDisputeSendMessageSuccess = createAction(constants.POST_MANAGE_DISPUTE_SEND_MESSAGE_SUCCESS);
export const postManageDisputeSendMessageError = createAction(constants.POST_MANAGE_DISPUTE_SEND_MESSAGE_ERROR);

export const getManageDisputeCarrierOptionsRequest = createAction(constants.GET_MANAGE_DISPUTE_CARRIER_OPTIONS_REQUEST);
export const getManageDisputeCarrierOptionsSuccess = createAction(constants.GET_MANAGE_DISPUTE_CARRIER_OPTIONS_SUCCESS);
export const getManageDisputeCarrierOptionsError = createAction(constants.GET_MANAGE_DISPUTE_CARRIER_OPTIONS_ERROR);

export const getManageDisputeEvidenceTypeOptionsRequest = createAction(constants.GET_MANAGE_DISPUTE_EVIDENCE_TYPE_OPTIONS_REQUEST);
export const getManageDisputeEvidenceTypeOptionsSuccess = createAction(constants.GET_MANAGE_DISPUTE_EVIDENCE_TYPE_OPTIONS_SUCCESS);
export const getManageDisputeEvidenceTypeOptionsError = createAction(constants.GET_MANAGE_DISPUTE_EVIDENCE_TYPE_OPTIONS_ERROR);

export const postManageDisputeAppealDisputeRequest = createAction(constants.POST_MANAGE_DISPUTE_APPEAL_DISPUTE_REQUEST);
export const postManageDisputeAppealDisputeSuccess = createAction(constants.POST_MANAGE_DISPUTE_APPEAL_DISPUTE_SUCCESS);
export const postManageDisputeAppealDisputeError = createAction(constants.POST_MANAGE_DISPUTE_APPEAL_DISPUTE_ERROR);

export const postManageDisputeManageManuallyRequest = createAction(constants.POST_MANAGE_DISPUTE_MANAGE_MANUALLY_REQUEST);
export const postManageDisputeManageManuallySuccess = createAction(constants.POST_MANAGE_DISPUTE_MANAGE_MANUALLY_SUCCESS);
export const postManageDisputeManageManuallyError = createAction(constants.POST_MANAGE_DISPUTE_MANAGE_MANUALLY_ERROR);

export const postManageDisputeAcknowledgeReturnItemRequest = createAction(constants.POST_MANAGE_ACKNOWLEDGE_RETURN_ITEM_REQUEST);
export const postManageDisputeAcknowledgeReturnItemSuccess = createAction(constants.POST_MANAGE_ACKNOWLEDGE_RETURN_ITEM_SUCCESS);
export const postManageDisputeAcknowledgeReturnItemError = createAction(constants.POST_MANAGE_ACKNOWLEDGE_RETURN_ITEM_ERROR);

export const postManageDisputeAcceptClaimRequest = createAction(constants.POST_MANAGE_ACCEPT_CLAIM_REQUEST);
export const postManageDisputeAcceptClaimSuccess = createAction(constants.POST_MANAGE_ACCEPT_CLAIM_SUCCESS);
export const postManageDisputeAcceptClaimError = createAction(constants.POST_MANAGE_ACCEPT_CLAIM_ERROR);

export const getManageDisputeHateoasLinkRequest = createAction(constants.GET_MANAGE_DISPUTE_HATEOAS_LINK_REQUEST);
export const getManageDisputeHateoasLinkSuccess = createAction(constants.GET_MANAGE_DISPUTE_HATEOAS_LINK_SUCCESS);
export const getManageDisputeHateoasLinkError = createAction(constants.GET_MANAGE_DISPUTE_HATEOAS_LINK_ERROR);

//dispute-template
export const postDisputeTemplateRequest = createAction(constants.POST_DISPUTE_TEMPLATE_REQUEST);
export const postDisputeTemplateSuccess = createAction(constants.POST_DISPUTE_TEMPLATE_SUCCESS);
export const postDisputeTemplateError = createAction(constants.POST_DISPUTE_TEMPLATE_ERROR);

export const getDisputeTemplateListRequest = createAction(constants.GET_DISPUTE_TEMPLATE_LIST_REQUEST);
export const getDisputeTemplateListSuccess = createAction(constants.GET_DISPUTE_TEMPLATE_LIST_SUCCESS);
export const getDisputeTemplateListError = createAction(constants.GET_DISPUTE_TEMPLATE_LIST_ERROR);

export const getDisputeTemplateFullListRequest = createAction(constants.GET_DISPUTE_TEMPLATE_FULL_LIST_REQUEST);
export const getDisputeTemplateFullListSuccess = createAction(constants.GET_DISPUTE_TEMPLATE_FULL_LIST_SUCCESS);
export const getDisputeTemplateFullListError = createAction(constants.GET_DISPUTE_TEMPLATE_FULL_LIST_ERROR);

export const getDisputeTemplateReasonListRequest = createAction(constants.GET_DISPUTE_TEMPLATE_REASON_LIST_REQUEST);
export const getDisputeTemplateReasonListSuccess = createAction(constants.GET_DISPUTE_TEMPLATE_REASON_LIST_SUCCESS);
export const getDisputeTemplateReasonListError = createAction(constants.GET_DISPUTE_TEMPLATE_REASON_LIST_ERROR);

export const getDisputeTemplateActionListRequest = createAction(constants.GET_DISPUTE_TEMPLATE_ACTION_LIST_REQUEST);
export const getDisputeTemplateActionListSuccess = createAction(constants.GET_DISPUTE_TEMPLATE_ACTION_LIST_SUCCESS);
export const getDisputeTemplateActionListError = createAction(constants.GET_DISPUTE_TEMPLATE_ACTION_LIST_ERROR);

export const getDisputeTemplateStageListRequest = createAction(constants.GET_DISPUTE_TEMPLATE_STAGE_LIST_REQUEST);
export const getDisputeTemplateStageListSuccess = createAction(constants.GET_DISPUTE_TEMPLATE_STAGE_LIST_SUCCESS);
export const getDisputeTemplateStageListError = createAction(constants.GET_DISPUTE_TEMPLATE_STAGE_LIST_ERROR);

export const getDisputeTemplateFindByTypeRequest = createAction(constants.GET_DISPUTE_TEMPLATE_FIND_BY_TYPE_REQUEST);
export const getDisputeTemplateFindByTypeSuccess = createAction(constants.GET_DISPUTE_TEMPLATE_FIND_BY_TYPE_SUCCESS);
export const getDisputeTemplateFindByTypeError = createAction(constants.GET_DISPUTE_TEMPLATE_FIND_BY_TYPE_ERROR);

export const getDisputeTemplateFindByStoreRequest = createAction(constants.GET_DISPUTE_TEMPLATE_FIND_BY_STORE_REQUEST);
export const getDisputeTemplateFindByStoreSuccess = createAction(constants.GET_DISPUTE_TEMPLATE_FIND_BY_STORE_SUCCESS);
export const getDisputeTemplateFindByStoreError = createAction(constants.GET_DISPUTE_TEMPLATE_FIND_BY_STORE_ERROR);

export const postDisputeTemplateFilterTemplatesRequest = createAction(constants.POST_DISPUTE_TEMPLATE_FILTER_TEMPLATES_REQUEST);
export const postDisputeTemplateFilterTemplatesSuccess = createAction(constants.POST_DISPUTE_TEMPLATE_FILTER_TEMPLATES_SUCCESS);
export const postDisputeTemplateFilterTemplatesError = createAction(constants.POST_DISPUTE_TEMPLATE_FILTER_TEMPLATES_ERROR);

export const getDisputeTemplateViewRequest = createAction(constants.GET_DISPUTE_TEMPLATE_VIEW_REQUEST);
export const getDisputeTemplateViewSuccess = createAction(constants.GET_DISPUTE_TEMPLATE_VIEW_SUCCESS);
export const getDisputeTemplateViewError = createAction(constants.GET_DISPUTE_TEMPLATE_VIEW_ERROR);

export const getDisputeTemplatePreviewPdfRequest = createAction(constants.GET_DISPUTE_TEMPLATE_PREVIEW_PDF_REQUEST);
export const getDisputeTemplatePreviewPdfSuccess = createAction(constants.GET_DISPUTE_TEMPLATE_PREVIEW_PDF_SUCCESS);
export const getDisputeTemplatePreviewPdfError = createAction(constants.GET_DISPUTE_TEMPLATE_PREVIEW_PDF_ERROR);

export const getDisputeTemplateFetchContentRequest = createAction(constants.GET_DISPUTE_TEMPLATE_FETCH_CONTENT_REQUEST);
export const getDisputeTemplateFetchContentSuccess = createAction(constants.GET_DISPUTE_TEMPLATE_FETCH_CONTENT_SUCCESS);
export const getDisputeTemplateFetchContentError = createAction(constants.GET_DISPUTE_TEMPLATE_FETCH_CONTENT_ERROR);

//dispute-response
export const getDisputeResponseFindAllRequest = createAction(constants.GET_DISPUTE_RESPONSE_FIND_ALL_REQUEST);
export const getDisputeResponseFindAllSuccess = createAction(constants.GET_DISPUTE_RESPONSE_FIND_ALL_SUCCESS);
export const getDisputeResponseFindAllError = createAction(constants.GET_DISPUTE_RESPONSE_FIND_ALL_ERROR);

export const getDisputeResponseFindByDisputeIdRequest = createAction(constants.GET_DISPUTE_RESPONSE_FIND_BY_DISPUTE_ID_REQUEST);
export const getDisputeResponseFindByDisputeIdSuccess = createAction(constants.GET_DISPUTE_RESPONSE_FIND_BY_DISPUTE_ID_SUCCESS);
export const getDisputeResponseFindByDisputeIdError = createAction(constants.GET_DISPUTE_RESPONSE_FIND_BY_DISPUTE_ID_ERROR);

export const postDisputeResponseCreateRequest = createAction(constants.POST_DISPUTE_RESPONSE_CREATE_REQUEST);
export const postDisputeResponseCreateSuccess = createAction(constants.POST_DISPUTE_RESPONSE_CREATE_SUCCESS);
export const postDisputeResponseCreateError = createAction(constants.POST_DISPUTE_RESPONSE_CREATE_ERROR);

export const getDisputeResponsePdfRequest = createAction(constants.GET_DISPUTE_RESPONSE_PDF_REQUEST);
export const getDisputeResponsePdfSuccess = createAction(constants.GET_DISPUTE_RESPONSE_PDF_SUCCESS);
export const getDisputeResponsePdfError = createAction(constants.GET_DISPUTE_RESPONSE_PDF_ERROR);

export const getDisputeResponsePdfByIdRequest = createAction(constants.GET_DISPUTE_RESPONSE_PDF_BY_ID_REQUEST);
export const getDisputeResponsePdfByIdSuccess = createAction(constants.GET_DISPUTE_RESPONSE_PDF_BY_ID_SUCCESS);
export const getDisputeResponsePdfByIdError = createAction(constants.GET_DISPUTE_RESPONSE_PDF_BY_ID_ERROR);

export const getDisputeResponseStatusSubmittedRequest = createAction(constants.GET_DISPUTE_RESPONSE_STATUS_SUBMITTED_REQUEST);
export const getDisputeResponseStatusSubmittedSuccess = createAction(constants.GET_DISPUTE_RESPONSE_STATUS_SUBMITTED_SUCCESS);
export const getDisputeResponseStatusSubmittedError = createAction(constants.GET_DISPUTE_RESPONSE_STATUS_SUBMITTED_ERROR);

export const getDisputeUpdateStatusSubmittedRequest = createAction(constants.GET_DISPUTE_UPDATE_RESPONSE_STATUS_SUBMITTED_REQUEST);
export const getDisputeUpdateStatusSubmittedSuccess = createAction(constants.GET_DISPUTE_UPDATE_RESPONSE_STATUS_SUBMITTED_SUCCESS);
export const getDisputeUpdateStatusSubmittedError = createAction(constants.GET_DISPUTE_UPDATE_RESPONSE_STATUS_SUBMITTED_ERROR);

export const getDisputeResponseStatusQueuedRequest = createAction(constants.GET_DISPUTE_RESPONSE_STATUS_QUEUED_REQUEST);
export const getDisputeResponseStatusQueuedSuccess = createAction(constants.GET_DISPUTE_RESPONSE_STATUS_QUEUED_SUCCESS);
export const getDisputeResponseStatusQueuedError = createAction(constants.GET_DISPUTE_RESPONSE_STATUS_QUEUED_ERROR);

export const getDisputeResponseStatusApprovedRequest = createAction(constants.GET_DISPUTE_RESPONSE_STATUS_APPROVED_REQUEST);
export const getDisputeResponseStatusApprovedSuccess = createAction(constants.GET_DISPUTE_RESPONSE_STATUS_APPROVED_SUCCESS);
export const getDisputeResponseStatusApprovedError = createAction(constants.GET_DISPUTE_RESPONSE_STATUS_APPROVED_ERROR);

export const getDisputeResponseFetchContentRequest = createAction(constants.GET_DISPUTE_RESPONSE_FETCH_CONTENT_REQUEST);
export const getDisputeResponseFetchContentSuccess = createAction(constants.GET_DISPUTE_RESPONSE_FETCH_CONTENT_SUCCESS);
export const getDisputeResponseFetchContentError = createAction(constants.GET_DISPUTE_RESPONSE_FETCH_CONTENT_ERROR);

export const postDisputeResponseSaveContentRequest = createAction(constants.POST_DISPUTE_RESPONSE_SAVE_CONTENT_REQUEST);
export const postDisputeResponseSaveContentSuccess = createAction(constants.POST_DISPUTE_RESPONSE_SAVE_CONTENT_SUCCESS);
export const postDisputeResponseSaveContentError = createAction(constants.POST_DISPUTE_RESPONSE_SAVE_CONTENT_ERROR);

export const getAllMacrosRequest = createAction(constants.GET_ALL_MACROS_REQUEST);
export const getAllMacrosSuccess = createAction(constants.GET_ALL_MACROS_SUCCESS);
export const getAllMacrosError = createAction(constants.GET_ALL_MACROS_ERROR);

export const getMissingMacrosRequest = createAction(constants.GET_MISSING_MACROS_REQUEST);
export const getMissingMacrosSuccess = createAction(constants.GET_MISSING_MACROS_SUCCESS);
export const getMissingMacrosError = createAction(constants.GET_MISSING_MACROS_ERROR);



export const PostSavingMissingMacrosRequest = createAction(constants.POST_SAVING_MISSING_MACROS_REQUEST);
export const PostSavingMissingMacrosSuccess = createAction(constants.POST_SAVING_MISSING_MACROS_SUCCESS);
export const PostSavingMissingMacrosError = createAction(constants.POST_SAVING_MISSING_MACROS_ERROR);

export const postDisputeResponseUpdateStatusApprovedRequest = createAction(constants.POST_DISPUTE_RESPONSE_UPDATE_STATUS_REQUEST);
export const postDisputeResponseUpdateStatusApprovedSuccess = createAction(constants.POST_DISPUTE_RESPONSE_UPDATE_STATUS_SUCCESS);
export const postDisputeResponseUpdateStatusApprovedError = createAction(constants.POST_DISPUTE_RESPONSE_UPDATE_STATUS_ERROR);

export const postCreateResponseRequest = createAction(constants.POST_CREATE_RESPONSE_REQUEST);
export const postCreateResponseSuccess = createAction(constants.POST_CREATE_RESPONSE_SUCCESS);
export const postCreateResponseError = createAction(constants.POST_CREATE_RESPONSE_ERROR);

export const deleteDisputeResponseRequest = createAction(constants.DELETE_DISPUTE_RESPONSE_REQUEST);
export const deleteDisputeResponseSuccess = createAction(constants.DELETE_DISPUTE_RESPONSE_SUCCESS);
export const deleteDisputeResponseError = createAction(constants.DELETE_DISPUTE_RESPONSE_ERROR);
export const deleteDisputeResponseClear = createAction(constants.DELETE_DISPUTE_RESPONSE_CLEAR);
//stores-shop
export const getShopAllShopsRequest = createAction(constants.GET_SHOP_ALL_SHOPS_REQUEST);
export const getShopAllShopsSuccess = createAction(constants.GET_SHOP_ALL_SHOPS_SUCCESS);
export const getShopAllShopsError = createAction(constants.GET_SHOP_ALL_SHOPS_ERROR);

export const getShopFindShopRequest = createAction(constants.GET_SHOP_FIND_SHOP_REQUEST);
export const getShopFindShopSuccess = createAction(constants.GET_SHOP_FIND_SHOP_SUCCESS);
export const getShopFindShopError = createAction(constants.GET_SHOP_FIND_SHOP_ERROR);

export const getShopFindActiveStoresRequest = createAction(constants.GET_SHOP_FIND_ACTIVE_STORES_REQUEST);
export const getShopFindActiveStoresSuccess = createAction(constants.GET_SHOP_FIND_ACTIVE_STORES_SUCCESS);
export const getShopFindActiveStoresError = createAction(constants.GET_SHOP_FIND_ACTIVE_STORES_ERROR);

export const getShopStoreReportRequest = createAction(constants.GET_SHOP_STORE_REPORT_REQUEST);
export const getShopStoreReportSuccess = createAction(constants.GET_SHOP_STORE_REPORT_SUCCESS);
export const getShopStoreReportError = createAction(constants.GET_SHOP_STORE_REPORT_ERROR)

export const postShopFilterShopsRequest = createAction(constants.POST_SHOP_FILTER_SHOPS_REQUEST);
export const postShopFilterShopsSuccess = createAction(constants.POST_SHOP_FILTER_SHOPS_SUCCESS);
export const postShopFilterShopsError = createAction(constants.POST_SHOP_FILTER_SHOPS_ERROR);

export const postShopsSaveAddonRequest = createAction(constants.POST_SHOPS_SAVE_ADD_ON_REQUEST);
export const postShopsSaveAddonSuccess = createAction(constants.POST_SHOPS_SAVE_ADD_ON_SUCCESS);
export const postShopsSaveAddonError = createAction(constants.POST_SHOPS_SAVE_ADD_ON_ERROR);

export const getShopFindShopAddonRequest = createAction(constants.GET_SHOP_FIND_SHOP_ADD_ON_REQUEST);
export const getShopFindShopAddonSuccess = createAction(constants.GET_SHOP_FIND_SHOP_ADD_ON_SUCCESS);
export const getShopFindShopAddonError = createAction(constants.GET_SHOP_FIND_SHOP_ADD_ON_ERROR);

export const getPreSignedUrlRequest = createAction(constants.GET_PRE_SIGNED_URL_REQUEST);
export const getPreSignedUrlSuccess = createAction(constants.GET_PRE_SIGNED_URL_SUCCESS);
export const getPreSignedUrlError = createAction(constants.GET_PRE_SIGNED_URL_ERROR);
export const getPreSignedUrlClear = createAction(constants.GET_PRE_SIGNED_URL_CLEAR);

export const updateShopCollaboratorAccessRequest = createAction(constants.UPDATE_SHOP_COLLABORATOR_ACCESS_REQUEST);
export const updateShopCollaboratorAccessSuccess = createAction(constants.UPDATE_SHOP_COLLABORATOR_ACCESS_SUCCESS);
export const updateShopCollaboratorAccessError = createAction(constants.UPDATE_SHOP_COLLABORATOR_ACCESS_ERROR);

// dispute-flow
export const postDisputeFlowRequest = createAction(constants.POST_DISPUTE_FLOW_REQUEST);
export const postDisputeFlowSuccess = createAction(constants.POST_DISPUTE_FLOW_SUCCESS);
export const postDisputeFlowError = createAction(constants.POST_DISPUTE_FLOW_ERROR);

export const postDisputeFlowDuplicateIdRequest = createAction(constants.POST_DISPUTE_FLOW_DUPLICATE_ID_REQUEST);
export const postDisputeFlowDuplicateIdSuccess = createAction(constants.POST_DISPUTE_FLOW_DUPLICATE_ID_SUCCESS);
export const postDisputeFlowDuplicateIdError = createAction(constants.POST_DISPUTE_FLOW_DUPLICATE_ID_ERROR);

export const getDisputeFlowListRequest = createAction(constants.GET_DISPUTE_FLOW_LIST_REQUEST);
export const getDisputeFlowListSuccess = createAction(constants.GET_DISPUTE_FLOW_LIST_SUCCESS);
export const getDisputeFlowListError = createAction(constants.GET_DISPUTE_FLOW_LIST_ERROR);

export const getDisputeFlowReasonListRequest = createAction(constants.GET_DISPUTE_FLOW_REASON_LIST_REQUEST);
export const getDisputeFlowReasonListSuccess = createAction(constants.GET_DISPUTE_FLOW_REASON_LIST_SUCCESS);
export const getDisputeFlowReasonListError = createAction(constants.GET_DISPUTE_FLOW_REASON_LIST_ERROR);

export const getDisputeFlowActionListRequest = createAction(constants.GET_DISPUTE_FLOW_ACTION_LIST_REQUEST);
export const getDisputeFlowActionListSuccess = createAction(constants.GET_DISPUTE_FLOW_ACTION_LIST_SUCCESS);
export const getDisputeFlowActionListError = createAction(constants.GET_DISPUTE_FLOW_ACTION_LIST_ERROR);

export const getDisputeFlowStageListRequest = createAction(constants.GET_DISPUTE_FLOW_STAGE_LIST_REQUEST);
export const getDisputeFlowStageListSuccess = createAction(constants.GET_DISPUTE_FLOW_STAGE_LIST_SUCCESS);
export const getDisputeFlowStageListError = createAction(constants.GET_DISPUTE_FLOW_STAGE_LIST_ERROR);

export const getDisputeFlowFindByTypeRequest = createAction(constants.GET_DISPUTE_FLOW_FIND_BY_TYPE_REQUEST);
export const getDisputeFlowFindByTypeSuccess = createAction(constants.GET_DISPUTE_FLOW_FIND_BY_TYPE_SUCCESS);
export const getDisputeFlowFindByTypeError = createAction(constants.GET_DISPUTE_FLOW_FIND_BY_TYPE_ERROR);

export const getDisputeFlowFindByStoreRequest = createAction(constants.GET_DISPUTE_FLOW_FIND_BY_STORE_REQUEST);
export const getDisputeFlowFindByStoreSuccess = createAction(constants.GET_DISPUTE_FLOW_FIND_BY_STORE_SUCCESS);
export const getDisputeFlowFindByStoreError = createAction(constants.GET_DISPUTE_FLOW_FIND_BY_STORE_ERROR);

export const postDisputeFlowFilterFlowsRequest = createAction(constants.POST_DISPUTE_FLOW_FILTER_FLOWS_REQUEST);
export const postDisputeFlowFilterFlowsSuccess = createAction(constants.POST_DISPUTE_FLOW_FILTER_FLOWS_SUCCESS);
export const postDisputeFlowFilterFlowsError = createAction(constants.POST_DISPUTE_FLOW_FILTER_FLOWS_ERROR);

export const getDisputeFlowViewRequest = createAction(constants.GET_DISPUTE_FLOW_VIEW_REQUEST);
export const getDisputeFlowViewSuccess = createAction(constants.GET_DISPUTE_FLOW_VIEW_SUCCESS);
export const getDisputeFlowViewError = createAction(constants.GET_DISPUTE_FLOW_VIEW_ERROR);
export const expandTheFlowEditor = createAction(constants.EXPAND_THE_FLOW_EDITOR);

//shop-save-company
export const postShopSaveCompanyAddonRequest = createAction(constants.POST_SHOP_SAVE_COMPANY_ADD_ON_REQUEST);
export const postShopSaveCompanyAddonSuccess = createAction(constants.POST_SHOP_SAVE_COMPANY_ADD_ON_SUCCESS);
export const postShopSaveCompanyAddonError = createAction(constants.POST_SHOP_SAVE_COMPANY_ADD_ON_ERROR);

export const postShopSaveCompanyPoliciesAddonRequest = createAction(constants.POST_SHOP_SAVE_COMPANY_POLICIES_ADD_ON_REQUEST);
export const postShopSaveCompanyPoliciesAddonSuccess = createAction(constants.POST_SHOP_SAVE_COMPANY_POLICIES_ADD_ON_SUCCESS);
export const postShopSaveCompanyPoliciesAddonError = createAction(constants.POST_SHOP_SAVE_COMPANY_POLICIES_ADD_ON_ERROR);

//Manage-user
export const getManagerListRequest = createAction(constants.GET_LIST_MANAGER_REQUEST);
export const getManagerListSuccess = createAction(constants.GET_LIST_MANAGER_SUCCESS);
export const getManagerListError = createAction(constants.GET_LIST_MANAGER_ERROR);

export const postUpdateManagerByShopIdRequest = createAction(constants.POST_UPDATE_MANAGER_BY_SHOPID_REQUEST);
export const postUpdateManagerByShopIdSuccess = createAction(constants.POST_UPDATE_MANAGER_BY_SHOPID_SUCCESS);
export const postUpdateManagerByShopIdError = createAction(constants.POST_UPDATE_MANAGER_BY_SHOPID_ERROR);

export const postUpdateManagerByDisputeIdRequest = createAction(constants.POST_UPDATE_MANAGER_BY_DISPUTEID_REQUEST);
export const postUpdateManagerByDisputeIdSuccess = createAction(constants.POST_UPDATE_MANAGER_BY_DISPUTEID_SUCCESS);
export const postUpdateManagerByDisputeIdError = createAction(constants.POST_UPDATE_MANAGER_BY_DISPUTEID_ERROR);

export const getShopFindByManagerRequest = createAction(constants.GET_SHOP_FIND_BY_MANAGER_REQUEST);
export const getShopFindByManagerSuccess = createAction(constants.GET_SHOP_FIND_BY_MANAGER_SUCCESS);
export const getShopFindByManagerError = createAction(constants.GET_SHOP_FIND_BY_MANAGER_ERROR);

export const getDisputeFindByManagerRequest = createAction(constants.GET_DISPUTE_FIND_BY_MANAGER_REQUEST);
export const getDisputeFindByManagerSuccess = createAction(constants.GET_DISPUTE_FIND_BY_MANAGER_SUCCESS);
export const getDisputeFindByManagerError = createAction(constants.GET_DISPUTE_FIND_BY_MANAGER_ERROR);

export const getUserListRequest = createAction(constants.GET_USER_LIST_REQUEST);
export const getUserListSuccess = createAction(constants.GET_USER_LIST_SUCCESS);
export const getUserListError = createAction(constants.GET_USER_LIST_ERROR);

export const postManageCreateUserRequest = createAction(constants.POST_MANAGE_CREATE_USER_REQUEST);
export const postManageCreateUserSuccess = createAction(constants.POST_MANAGE_CREATE_USER_SUCCESS);
export const postManageCreateUserError = createAction(constants.POST_MANAGE_CREATE_USER_ERROR);

// klarna
export const getKlarnaByDisputeRequest = createAction(constants.GET_KLARNA_REQUESTED_FIELDS_BY_DISPUTE_REQUEST)
export const getKlarnaByDisputeSuccess = createAction(constants.GET_KLARNA_REQUESTED_FIELDS_BY_DISPUTE_SUCCESS)
export const getKlarnaByDisputeError = createAction(constants.GET_KLARNA_REQUESTED_FIELDS_BY_DISPUTE_ERROR)

//Billing
export const postDisputeBillingRequest = createAction(constants.POST_DISPUTE_BILLING_REQUEST)
export const postDisputeBillingSuccess = createAction(constants.POST_DISPUTE_BILLING_SUCCESS)
export const postDisputeBillingError = createAction(constants.POST_DISPUTE_BILLING_ERROR)

export const postDisputeApproveRequest = createAction(constants.POST_DISPUTE_APPROVE_REQUEST)
export const postDisputeApproveSuccess = createAction(constants.POST_DISPUTE_APPROVE_SUCCESS)
export const postDisputeApproveError = createAction(constants.POST_DISPUTE_APPROVE_ERROR)
export const removeDisputeApproveRequest = createAction(constants.REMOVE_DISPUTE_APPROVE_ERROR)

export const postDisputeReadyStatusRequest = createAction(constants.POST_DISPUTE_READY_STATUS_REQUEST)
export const postDisputeReadyStatusSuccess = createAction(constants.POST_DISPUTE_READY_STATUS_SUCCESS)
export const postDisputeReadyStatusError = createAction(constants.POST_DISPUTE_READY_STATUS_ERROR)

export const postDisputePaypalPullReportRequest = createAction(constants.POST_DISPUTE_PAYPAL_PULL_REPORT_REQUEST)
export const postDisputePaypalPullReportSuccess = createAction(constants.POST_DISPUTE_PAYPAL_PULL_REPORT_SUCCESS)
export const postDisputePaypalPullReportError = createAction(constants.POST_DISPUTE_PAYPAL_PULL_REPORT_ERROR)

export const postNewBillingListRequest = createAction(constants.POST_NEW_BILLING_LIST_REQUEST)
export const postNewBillingListSuccess = createAction(constants.POST_NEW_BILLING_LIST_SUCCESS)
export const postNewBillingListError = createAction(constants.POST_NEW_BILLING_LIST_ERROR)

export const getNewBillingReadyDisputeListRequest = createAction(constants.GET_NEW_BILLING_READY_DISPUT_REQUEST)
export const getNewBillingReadyDisputeListSuccess = createAction(constants.GET_NEW_BILLING_READY_DISPUT_SUCCESS)
export const getNewBillingReadyDisputeListError = createAction(constants.GET_NEW_BILLING_READY_DISPUT_ERROR)

export const getTotalBilledAmountRequest = createAction(constants.GET_TOTAL_BILLED_AMOUNT_REQUEST)
export const getTotalBilledAmountSuccess = createAction(constants.GET_TOTAL_BILLED_AMOUNT_SUCCESS)
export const getTotalBilledAmountError = createAction(constants.GET_TOTAL_BILLED_AMOUNT_ERROR)

export const getTotalAmountRequest = createAction(constants.GET_TOTAL_AMOUNT_REQUEST)
export const getTotalAmountSuccess = createAction(constants.GET_TOTAL_AMOUNT_SUCCESS)
export const getTotalAmountError = createAction(constants.GET_TOTAL_AMOUNT_ERROR)

export const getNewBillingTotalBilledAmountRequest = createAction(constants.GET_NEW_BILLING_TOTAL_BILLED_AMOUNT_REQUEST)
export const getNewBillingTotalBilledAmountSuccess = createAction(constants.GET_NEW_BILLING_TOTAL_BILLED_AMOUNT_SUCCESS)
export const getNewBillingTotalBilledAmountError = createAction(constants.GET_NEW_BILLING_TOTAL_BILLED_AMOUNT_ERROR)

export const getNewBillingTotalAmountRequest = createAction(constants.GET_NEW_BILLING_TOTAL_AMOUNT_REQUEST)
export const getNewBillingTotalAmountSuccess = createAction(constants.GET_NEW_BILLING_TOTAL_AMOUNT_SUCCESS)
export const getNewBillingTotalAmountError = createAction(constants.GET_NEW_BILLING_TOTAL_AMOUNT_ERROR)

export const getReadyDisputeTotalBilledAmountRequest = createAction(constants.GET_READY_DISPUTE_TOTAL_BILLED_AMOUNT_REQUEST)
export const getReadyDisputeTotalBilledAmountSuccess = createAction(constants.GET_READY_DISPUTE_TOTAL_BILLED_AMOUNT_SUCCESS)
export const getReadyDisputeTotalBilledAmountError = createAction(constants.GET_READY_DISPUTE_TOTAL_BILLED_AMOUNT_ERROR)

export const getReadyDisputeTotalAmountRequest = createAction(constants.GET_READY_DISPUTE_TOTAL_AMOUNT_REQUEST)
export const getReadyDisputeTotalAmountSuccess = createAction(constants.GET_READY_DISPUTE_TOTAL_AMOUNT_SUCCESS)
export const getReadyDisputeTotalAmountError = createAction(constants.GET_READY_DISPUTE_TOTAL_AMOUNT_ERROR)

//Reporting
export const getReportingListRequest = createAction(constants.GET_REPORTING_REQUEST);
export const getReportingListSuccess = createAction(constants.GET_REPORTING_SUCCESS);
export const getReportingListError = createAction(constants.GET_REPORTING_ERROR);
//by-stores-Reporting
export const getByStoresReportingListRequest = createAction(constants.GET_BY_STORES_REPORTING_REQUEST);
export const getByStoresReportingListSuccess = createAction(constants.GET_BY_STORES_REPORTING_SUCCESS);
export const getByStoresReportingListError = createAction(constants.GET_BY_STORES_REPORTING_ERROR);
//by-psp-Reporting
export const getByPspReportingListRequest = createAction(constants.GET_BY_PSP_REPORTING_REQUEST);
export const getByPspReportingListSuccess = createAction(constants.GET_BY_PSP_REPORTING_SUCCESS);
export const getByPspReportingListError = createAction(constants.GET_BY_PSP_REPORTING_ERROR);
//by-psp-Reporting
export const getByReasonsReportingListRequest = createAction(constants.GET_BY_REASONS_REPORTING_REQUEST);
export const getByReasonsReportingListSuccess = createAction(constants.GET_BY_REASONS_REPORTING_SUCCESS);
export const getByReasonsReportingListError = createAction(constants.GET_BY_REASONS_REPORTING_ERROR);
//by-monthly-Reporting
export const getByMonthlyReportingListRequest = createAction(constants.GET_BY_MONTHLY_REPORTING_REQUEST);
export const getByMonthlyReportingListSuccess = createAction(constants.GET_BY_MONTHLY_REPORTING_SUCCESS);
export const getByMonthlyReportingListError = createAction(constants.GET_BY_MONTHLY_REPORTING_ERROR);

// awaiting- seller - response
export const getAwaitingDisputesRequest = createAction(constants.GET_AWAITING_DISPUTES_REQUEST);
export const getAwaitingDisputesSuccess = createAction(constants.GET_AWAITING_DISPUTES_SUCCESS);
export const getAwaitingDisputesError = createAction(constants.GET_AWAITING_DISPUTES_ERROR);

export const getAwaitingDisputesTotalBilledAmountRequest = createAction(constants.GET_AWAITING_DISPUTES_TOTAL_BILLED_AMOUNT_REQUEST);
export const getAwaitingDisputesTotalBilledAmountSuccess = createAction(constants.GET_AWAITING_DISPUTES_TOTAL_BILLED_AMOUNT_SUCCESS);
export const getAwaitingDisputesTotalBilledAmountError = createAction(constants.GET_AWAITING_DISPUTES_TOTAL_BILLED_AMOUNT_ERROR);

export const getAwaitingDisputesTotalAmountRequest = createAction(constants.GET_AWAITING_DISPUTES_TOTAL_AMOUNT_REQUEST);
export const getAwaitingDisputesTotalAmountSuccess = createAction(constants.GET_AWAITING_DISPUTES_TOTAL_AMOUNT_SUCCESS);
export const getAwaitingDisputesTotalAmountError = createAction(constants.GET_AWAITING_DISPUTES_TOTAL_AMOUNT_ERROR);

//filter-awaiting-seller-response

export const getFilteredWaitingSellerRequest = createAction(constants.GET_FILTERED_WAITING_SELLER_REQUEST);
export const getFilteredWaitingSellerSuccess = createAction(constants.GET_FILTERED_WAITING_SELLER_SUCCESS);
export const getFilteredWaitingSellerError = createAction(constants.GET_FILTERED_WAITING_SELLER_ERROR);

// fetch under review disputes
export const getFetchUnderReviewDisputesRequest = createAction(constants.GET_FETCH_UNDER_REVIEW_DIPSUTES_REQUEST);
export const getFetchUnderReviewDisputesSuccess = createAction(constants.GET_FETCH_UNDER_REVIEW_DISPUTES_SUCCESS);
export const getFetchUnderReviewDisputesError = createAction(constants.GET_FETCH_UNDER_REVIEW_DISPUTES_ERROR);

export const getFetchUnderReviewTotalBilledAmountRequest = createAction(constants.GET_UNDER_REVIEW_TOTAL_BILLED_AMOUNT_REQUEST);
export const getFetchUnderReviewTotalBilledAmountSuccess = createAction(constants.GET_UNDER_REVIEW_TOTAL_BILLED_AMOUNT_SUCCESS);
export const getFetchUnderReviewTotalBilledAmountError = createAction(constants.GET_UNDER_REVIEW_TOTAL_BILLED_AMOUNT_ERROR);

export const getFetchUnderReviewTotalAmountRequest = createAction(constants.GET_UNDER_REVIEW_TOTAL_AMOUNT_REQUEST);
export const getFetchUnderReviewTotalAmountSuccess = createAction(constants.GET_UNDER_REVIEW_TOTAL_AMOUNT_SUCCESS);
export const getFetchUnderReviewTotalAmountError = createAction(constants.GET_UNDER_REVIEW_TOTAL_AMOUNT_ERROR);



