import { DisputesWidget, Loading, Pagination } from 'components';
import styles from './style.module.scss';
import { Button } from 'components/ui';
import { useDispatch } from 'react-redux';
import {
  postDisputeApproveRequest,
  removeDisputeApproveRequest,
} from 'redux/actions';
import { Box, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import ReusableTable from 'components/table';
import Modal from '@mui/material/Modal';
import { IconRender } from 'elements';
import ReactSelect from 'react-select';
import { useFindActiveStores, useNewBillingTabs } from 'hooks';
import Swal from 'sweetalert2';
import { getDisputeCurrency } from 'utils/disputeUtils';
import { isEmpty } from 'lodash';

type NewBillingTypes = {
  selectedDisputes: any;
  handleCheckboxChange: any;
  mapedForm?: any;
  formFunction: any;
  // page: any;
  // pageLength: any;
  // onPageChange: any;
  setSelectedDisputes: any;
  // setPage: any;
};
const newBillingTableHeading = [
  'Dispute ID',
  'Store',
  'Order Number',
  'Amount',
  'Billable',
  // 'Status',
  'Dispute Reason',
  'Date Opened',
  'Decision Date',
  'Response SubmittedAt',
  'Action',
];
const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  height: '500px',
  overflowY: 'auto',
};
type DisputeData = {
  disputeId: string;
  disputeAmount: number;
  currencyCode: string;
};

const NewBilling = ({
  selectedDisputes,
  handleCheckboxChange,
  formFunction,
  // page,
  // setPage,
  // pageLength,
  // onPageChange,
  setSelectedDisputes,
}: NewBillingTypes) => {
  //TODO need to make these in the hook and refactor the code.
  const dispatch = useDispatch();
  const selectRef: any = useRef(null);
  const checkBoxVisible = true;
  // custom hook for find active stores
  const { findActiveStoreOptions } = useFindActiveStores();
  // custom hook for newBilling table and card data
  const {
    newBillingTotalAmount,
    newBillingTotalBilledAmount,
    isTotalAmountLoading,
    isTotalBilledLoading,
    newBillingTableData,
    isLoading,
    isSuccess,
    isError,
    billedDisputes,
    isApprovedSuccess,
    isApprovedError,
    shopList,
    setShopList,
    open,
    setOpen,
    setCallApi,
    callApi,
    handleClose,
    updatedDisputeIds,
    page,
    pageLength,
    onPageChange
  } = useNewBillingTabs();
  const [filterSuccessData, setFilterSuccessData] = useState<DisputeData[]>([]);
  console.log({ isApprovedSuccess });
  useEffect(() => {
    const updatedTableData =
      newBillingTableData?.billingReportResponses?.length > 0
        ? newBillingTableData.billingReportResponses.map((item: any) => ({
            disputeId: item?.disputeId,
            disputeAmount: item?.disputedAmount,
            currencyCode: item?.currencyCode,
          }))
        : [];
    const finalFilterData =
      updatedTableData?.length > 0
        ? updatedTableData.filter((item: DisputeData) =>
            selectedDisputes?.includes(item.disputeId)
          )
        : [];

    setFilterSuccessData(finalFilterData);
  }, [newBillingTableData, selectedDisputes]);

  const handleDisputeApproveCharges = () => {
    const successMessages = filterSuccessData
      ?.map(item => {
        return `<p>20% of disputed amount: ${
          getDisputeCurrency(item?.currencyCode as string)?.currencySign
        } ${item.disputeAmount} for dispute id: ${item.disputeId}</p>`;
      })
      .join('');
    if (selectedDisputes?.length > 0) {
      Swal.fire({
        title: 'Disputes will be charged at 20% of amount',
        html: `<div style="display:flex; flex-direction:column;padding:10px;background:#ededed;height:250px; overflow:auto;">${successMessages}</div>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Create Charge',
      }).then(result => {
        if (result.isConfirmed) {
          setCallApi(true);
          dispatch(postDisputeApproveRequest(selectedDisputes));
        }
      });
    }
    setSelectedDisputes([]);
  };
  useEffect(() => {
    if (callApi) {
      if (isApprovedSuccess) {
        // Handle success
        Swal.fire({
          title: 'Success',
          html:
            updatedDisputeIds?.length > 0 &&
            `<p>The following disputes will be billed in the next billing cycle:</p>${updatedDisputeIds
              ?.map(
                (id: string) =>
                  `<p style="font-size:16px;">Dispute id: ${id}</p>`
              )
              .join('')}`,
          icon: 'success',
        }).then(result => {
          if (result.isConfirmed || result.dismiss) {
            dispatch(removeDisputeApproveRequest());
            setCallApi(false);
          }
        });
      } else if (isApprovedError) {
        // Handle error
        Swal.fire({
          title: 'Something went wrong',
          text: 'Please try again later',
          icon: 'error',
        }).then(result => {
          if (result.isConfirmed || result.dismiss) {
            dispatch(removeDisputeApproveRequest());
            setCallApi(false);
          }
        });
      }
    }
  }, [isApprovedSuccess, isApprovedError, callApi]);

  // cards data
  const totalCountCard = {
    title: 'Total Count',
    blueEye: true,
    icon: <IconRender iconName='layersThree' />,
    totalDisputes: newBillingTableData?.count || 0,
    loading: isLoading,
  };
  const totalAmountCard = {
    title: 'Total Amount',
    blueEye: true,
    icon: <IconRender iconName='activity' />,
    disputedAmount: newBillingTotalAmount || 0,
    loading: isTotalAmountLoading,
  };
  const totalBillableAmountCard = {
    title: 'Total Billiable Amount',
    blueEye: true,
    icon: <IconRender iconName='activity' />,
    disputedAmount: newBillingTotalBilledAmount || 0,
    loading: isTotalBilledLoading,
  };

  return (
    <>
      <div className={styles.newBillingTableWrapper}>
        <div className={styles.cardsWrapper}>
          <div className={styles.card}>
            <DisputesWidget disputeWidgetData={totalCountCard} />
          </div>

          <div className={styles.card}>
            <DisputesWidget disputeWidgetData={totalAmountCard} />
          </div>

          <div className={styles.card}>
            <DisputesWidget disputeWidgetData={totalBillableAmountCard} />
          </div>
        </div>
        <Box className={styles.inputContainer}>
          <Button
            {...{
              btnLabel: 'Create Charges',
              btnTitle: 'create charges',
              onClick: () => handleDisputeApproveCharges(),
              isDisable: selectedDisputes.length > 0 ? false : true,
            }}
          />
          <div className={styles.searchDiv}>
            <ReactSelect
              ref={selectRef}
              className={styles.multipleSelect}
              styles={{
                control: baseStyles => ({
                  ...baseStyles,
                  minHeight: '44px',
                }),
              }}
              closeMenuOnSelect={false}
              placeholder={'Select stores...'}
              options={findActiveStoreOptions}
              isMulti
              value={shopList}
              onChange={(e: any) => setShopList(e)}
            />
          </div>
        </Box>
        {isLoading ? (
          <div style={{ background: '#fff' }}>
            <Loading />
          </div>
        ) : (
          <ReusableTable
            {...{
              usersColumns: newBillingTableHeading,
              data: newBillingTableData,
              isLoading,
              isSuccess,
              isError,
              selectedDisputes,
              handleCheckboxChange,
              checkBoxVisible,
              isPdfButton: true,
            }}
          />
        )}
        {Math.ceil(newBillingTableData?.count / pageLength) > 1 && (
          <div>
            <Pagination
              onPageChange={pageNum => onPageChange(pageNum)}
              page={page}
              totalPages={Math.ceil(newBillingTableData?.count / pageLength)}
            />
          </div>
        )}
        {/* code for modal */}
        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={modalStyle}>
              <Typography id='modal-modal-title' variant='h5' component='h2'>
                Total price exceeds balance remaining
              </Typography>
              <div style={{ marginTop: '20px' }}>
                {updatedDisputeIds?.length > 0 &&
                  updatedDisputeIds?.map((id: string, index: number) => (
                    <p style={{ fontSize: '16px' }}>Dispute id: {id}</p>
                  ))}
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default NewBilling;
