import React, {
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import {
  getAwaitingDisputesRequest,
  getAwaitingDisputesTotalBilledAmountRequest,
  getAwaitingDisputesTotalAmountRequest,
} from 'redux/actions';
import {
  SingleAccountBreadcrumb,
  Pagination,
  DisputesWidget,
} from 'components';
import styles from './style.module.scss';
import NewDisputeList, { DisputeResponseData } from 'components/disputeDashboard/newDisputeList';
import { BreadcumbItem } from 'components/ui';
import { getItem } from 'utils/localStorage';
import { useBreadCumbs, useDisputeReasonList } from 'hooks';
import Loading from 'components/loading/loading';
import cn from 'classnames';
import { Button } from '@mui/material';
import { underReviewStatusSection } from 'utils/disputeUtils';
import ReactSelect from 'react-select';
import { IconRender } from 'elements';
export type StateResponse = {
  isLoading:boolean;
  isSuccess:boolean;
  isError:boolean;
  data : {
    count:number;
    disputeResponseList:DisputeResponseData[]
  }
}
type ReasonSelectProps = {
  description: string;
  disputeReasonValue: string;
};

const awaitingPageTableHeading = [
  '',
  'Dispute ID + Store',
  // 'Shop Name',
  'Response Due Date',
  'Dispute Amount',
  'Billable Amount',
  'Reason',
  'Carrier',
  'Last Mile Carrier',
  'Response Status',
  // 'Manager',
  'Action',
];
interface Props {
  reason?: ReasonSelectProps;
}
const WaitingSellerResponse = ({ reason }: Props) => {
  const dispatch = useDispatch();
  const SingleAccountCheck = getItem('singleAccountCheck');
  const { breadCumbs } = useBreadCumbs({ pageName: 'Waiting Seller Response' });
  const selectRef: any = useRef(null);
  const { reasonList: reasonsOptions } = useDisputeReasonList();
  const [reasonsList, setReasonsList] = useState<ReasonSelectProps[]>([]);
  const [disputesPayload, setDisputesPayload] = useState<any>();
  const [page, setPage] = useState(1);
  const pageLength = 15;
  const [disputeStatusBtnCheck, setDisputeStatusBtnCheck] = useState(false);
  const [disputeClick, setDisputeClick] = useState(false);

  // this is for main table data
  const underReviewState:StateResponse = useSelector(
    (state: any) => state?.awaitingDisputes?.awaitingDisputes
  );
  const {isLoading,isSuccess,isError,data} = underReviewState
  // this is for waiting seller response total billed amount
  const {data:awaitingTotalBilledAmount} = useSelector(
    (state: any) => state?.awaitingDisputes?.awaitingSellerTotalBilledAmount
  );
  // this is for waiting seller response total amount
  const { data: awaitingTotalAmount } = useSelector(
    (state: any) => state?.awaitingDisputes?.awaitingSellerTotalAmount
  );
  // for pagination
  const onPageChange = (pageNumber: number) => {
    setPage(pageNumber);
  };
  // for handling payment processors button
  const [disputeStatusButton, setDisputeStatusButton] = useState([
    ...underReviewStatusSection,
  ]);

  const handleDisputeStatus = (disputeStatus: any, index: number) => {
    const disputeBtn = cloneDeep(disputeStatusButton);
    disputeStatus.status = !disputeStatus.status;
    setDisputeStatusBtnCheck(!disputeStatusBtnCheck);
    setDisputeClick(true);
    disputeBtn[index] = disputeStatus;
    setDisputeStatusButton([...disputeBtn]);
  };
  // for handleStatus filter payload
  const handleStatusFilterPayload = (statusFilter: string[]) => {
    if (!SingleAccountCheck) {
      return {
        ...disputesPayload,
        paymentProcessors: statusFilter?.length ? statusFilter : null,
      };
    }
  };

  useEffect(() => {
    setPage(1)
    if (disputeClick) {
      const statusArr = disputeStatusButton.filter(
        statusData => statusData.status
      );
      const statusFilterArr = statusArr.map(item => {
        return item?.title?.toUpperCase();
      });
      const payload = handleStatusFilterPayload(statusFilterArr);
      setDisputesPayload(payload); //set the payload by filtering status button
    }
  }, [disputeStatusBtnCheck, disputeStatusButton]);
  // for handle keydown in select component
  const blur = () => {
    selectRef.current?.blur();
  };
  const handleKeyDown: KeyboardEventHandler = (
    event: React.KeyboardEvent<Element>
  ) => {
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        blur();
        const mappedReasons: any = reasonsList.map((item: any) => item.value);
        const payload = {
          ...disputesPayload,
          reason: mappedReasons.length ? mappedReasons : null,
        };
        setDisputesPayload(payload);
        event.preventDefault();
    }
  };
  useEffect(() => {
    setPage(1)
    if (reasonsList.length > 0) {
      const maped: any = reasonsList.map((item: any) => item.value);
      const payload = {
        ...disputesPayload,
        reason: maped.length ? maped : null,
      };
      setDisputesPayload(payload);
    }
  }, [reasonsList]);

  // for calling all api
  useEffect(() => {
    const payload = {
      page,
      pageLength,
      ...disputesPayload,
    };
    dispatch(getAwaitingDisputesRequest(payload));
    dispatch(getAwaitingDisputesTotalAmountRequest({...payload,cardType:"totalAmount"}));
    dispatch(getAwaitingDisputesTotalBilledAmountRequest({...payload,cardType:"totalBilledAmount"}));
  }, [page, disputesPayload]);

  // cards data
  const totalCountCard = {
    title: 'Total Count     ',
    blueEye: true,
    icon: <IconRender iconName='layersThree' />,
    totalDisputes: data?.count || 0,
    loading: isLoading,
  };
  const totalAmountCard = {
    title: 'Total Amount',
    blueEye: true,
    icon: <IconRender iconName='activity' />,
    disputedAmount: awaitingTotalAmount || 0,
    loading: isLoading,
  };
  const totalBillableAmountCard = {
    title: 'Total Billiable Amount',
    blueEye: true,
    icon: <IconRender iconName='activity' />,
    disputedAmount: awaitingTotalBilledAmount || 0,
    loading: isLoading,
  };

  return (
    <div className={styles.disputesWrapper}>
      
      {SingleAccountCheck === 'true' ? (
        <SingleAccountBreadcrumb />
      ) : (
        <div className={styles.nav}>
          <h3>Waiting Seller Response</h3>
          <div className={styles.navPath}>
            {breadCumbs?.map((item, key) => (
              <BreadcumbItem key={key} {...item} />
            ))}
          </div>
        </div>
      )}
      <div className={styles.cardsWrapper}>
        <div className={styles.card}>
          <DisputesWidget disputeWidgetData={totalCountCard} />
        </div>

        <div className={styles.card}>
          <DisputesWidget disputeWidgetData={totalAmountCard} />
        </div>

        <div className={styles.card}>
          <DisputesWidget disputeWidgetData={totalBillableAmountCard} />
        </div>
      </div>
      <div className={styles.status}>
        {disputeStatusButton?.map((statusBtn, index) => {
          return (
            <Button
              key={statusBtn.title}
              data-testid='dispute-status-button'
              className={cn(
                statusBtn.status && styles.allDisputes,
                styles.statusDiv
              )}
              onClick={() => handleDisputeStatus({ ...statusBtn }, index)}
            >
              <div
                className={cn(styles.open)}
                style={{
                  background: statusBtn.status ? '#ffffff' : statusBtn.color,
                }}
              ></div>
              {statusBtn?.title?.split('_').join(' ')}
            </Button>
          );
        })}
      </div>

      <div className={styles.search}>
        <div className={styles.searchTop}>
          <div className={styles.searchDiv}>
            <ReactSelect
              ref={selectRef}
              className={styles.multipleSelect}
              styles={{
                control: baseStyles => ({
                  ...baseStyles,
                  minHeight: '44px',
                }),
              }}
              closeMenuOnSelect={false}
              onKeyDown={handleKeyDown}
              placeholder={'Select reason..'}
              options={reasonsOptions}
              isMulti
              value={reasonsList}
              onChange={(e: any) => setReasonsList(e)}
            />
          </div>
        </div>
      </div>

      <div>
        {isLoading ? (
          <div style={{ background: '#fff', borderRadius: '8px' }}>
            <Loading />
          </div>
        ) : (
          <NewDisputeList
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            data={data}
            DisputeListColumnNames={awaitingPageTableHeading}
            handleDisputeStatus={dispute => dispute.status}
            isShowCollapsibleTablel={true}
          />
        )}
      </div>
      {Math.ceil(data?.count / pageLength) > 1 && (
        <div>
          <Pagination
            onPageChange={pageNum => onPageChange(pageNum)}
            page={page}
            totalPages={Math.ceil(data?.count / pageLength)}
          />
        </div>
      )}
    </div>
  );
};

export default WaitingSellerResponse;
